

import WhatsAppMensaje from "../interfaces/WhatsAppMensaje.interface";

const getEstructura = (): WhatsAppMensaje => {
    const whatsAppMensaje: WhatsAppMensaje = {
        IdWhatsAppMensaje: 0,
        titulo: "",
        descripcion: "",
        texto: ""
    };
    return whatsAppMensaje;
}

const cast = (data: any): WhatsAppMensaje => {
    const whatsAppMensaje: WhatsAppMensaje = {
        IdWhatsAppMensaje: data.IdWhatsAppMensaje,
        titulo: data.titulo,
        descripcion: data.descripcion,
        texto: data.texto
    };
    return whatsAppMensaje;
}

const WhatsAppModel = {
    getEstructura,
    cast
}

export default WhatsAppModel