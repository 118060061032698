import ApiConfig from "../config/Api.config";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";

const url = `${ApiConfig.URLBase}/estados`

const listar = async (): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar`,
        metodo: "POST",        
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const EstadoService = {
    listar
};

export default EstadoService;