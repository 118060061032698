import { FC, useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import colors from "../config/Colors.config";
import { Button, Col, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";
import { IconButton } from "@mui/material";
import { Groups, AccountCircle, ArrowBackIos, ArrowForwardIos, Close, Computer, Group, Logout, PersonAddAlt1, List } from "@mui/icons-material";
import Block from "../components/Block.component";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Home as IconHome, Menu as MenuIcon, Email, WhatsApp, Settings, Message } from "@mui/icons-material";
import UsuarioModel from "../models/Usuario.model";
import Home from "./index/Home.index";
import Device from "../interfaces/Device.interface";
import DeviceConfig from "../config/Device.config";
import imgLogo from "../assets/img/logo.png"
import ListaCliente from "./index/Clientes/ListaCliente.clientes";
import queryString from "query-string";
import InfoCliente from "./index/Clientes/InfoCliente.clientes";
import CarcaCorreosIndex from "./index/CarcaCorreos.index";
import WhatsAppPage from "./WhatsApp.page";
import Usuarios from "./Usuarios.page";
import MiPerfil from "./MiPerfil.page";
import Usuario from "../interfaces/Usuario.interface";
import Swal from "sweetalert2";
import Tiempo from "../tools/Tiempo.tool";
import WhatsAppConfig from "./WhatsAppConfig.page";

const Dashboard: FC = () => {

    const [collapsed, setCollapsed] = useState<boolean>(true);
    const [indexPage, setIndexPage] = useState<number>(0);
    const [isOpenCanvas, setIsOpenCanvas] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState({ 0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false, 9: false, 10: false, 11: false, 12: false, 13: false, 14: false, 15: false, 16: false, 17: false });
    //const usuario: Usuario = UsuarioModel.getEstructura();
    const [usuario, setUsuario] = useState(UsuarioModel.getEstructura());

    const [data] = useState<Device>(DeviceConfig.data());

    const location = useLocation();
    const navigate = useNavigate();

    const changeIndexPage = (index: number): void => {
        setIndexPage(index);
        if (index === 1) {
            const parsedUrl = queryString.parseUrl(location.search);
            const { texto } = parsedUrl.query;
            if (texto !== "" && texto !== "undefined" && texto !== undefined) {
                navigate(`/dashboard/?page=${index}&texto=${texto}`);
            }
        }

        if (index === 2) {
            const parsedUrl = queryString.parseUrl(location.search);
            const { uuid } = parsedUrl.query;
            if (uuid !== "" && uuid !== "undefined" && uuid !== undefined) {
                navigate(`/dashboard/?page=${index}&uuid=${uuid}`);
            }
        } else {
            navigate(`/dashboard/?page=${index}`);
        }

    };

    const handleMouseEnter = (index: number) => {
        setIsHovered((prev) => ({ ...prev, [index]: true }));
    };

    const handleMouseLeave = (index: number) => {
        setIsHovered((prev) => ({ ...prev, [index]: false }));
    };

    const validarDatosUsuario = async (): Promise<void> => {
        const data: Usuario | undefined = await UsuarioModel.getUsuario();
        if (data) {
            setUsuario(data);
            const parsedUrl = queryString.parseUrl(location.search);
            let page: string = `${parsedUrl.query.page}`;
            if (page === undefined || page === null || page === "") page = "0";
            const index: number = parseInt(page);
            changeIndexPage(index);
        } else {
            document.body.innerHTML = `<body></body>`
            Swal.fire({
                icon: "warning",
                title: "ACCESO DENEGADO",
                text: `Tienes que iniciar sesión para poder usar el sistema, en breve se te redireccionará al login`,
                showConfirmButton: true,
                confirmButtonColor: colors.blue,
                denyButtonColor: colors.blue,
                // cancelButtonText: "No",
                confirmButtonText: "Ok",
                reverseButtons: true,
                // showCancelButton: true,
                timer: 10000,
                timerProgressBar: true,
                // footer: ` <label> <i> No podrás recuperar la cuenta actualmente vinculada </i> </label>`
            }).then(async (response) => {
                if (response.isConfirmed) {
                    window.location.href = "/";
                }
            })
            Tiempo.sleep(10).then(() => window.location.href = "/");
        }
    }

    useEffect(() => {
        validarDatosUsuario();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section style={{ width: "100%", minHeight: "100vh", height: "auto", maxHeight: "auto", backgroundColor: colors.white01 }} >
            {
                data.isDesktop ?
                    <Sidebar
                        style={{
                            backgroundColor: colors.blue,
                            color: colors.white01,
                            height: "100vh",
                            fontWeight: "bold",
                            position: "fixed",
                            width: collapsed ? "" : 320,
                            zIndex: 3,
                        }}
                        collapsed={collapsed}
                    >
                        <Menu
                            style={{ backgroundColor: colors.blue, marginTop: 0, height: "100%" }}
                        >
                            <Block height={10} />
                            <Row style={{ marginTop: 0, marginBottom: 10, marginLeft: 0, marginRight: 0 }} >
                                <Col style={{ textAlign: collapsed ? "center" : "right" }} >
                                    <IconButton
                                        onClick={() => {
                                            setCollapsed(!collapsed)
                                        }}
                                        style={{
                                            color: colors.white01
                                        }}
                                    >
                                        {
                                            collapsed ?
                                                <ArrowForwardIos />
                                                :
                                                <ArrowBackIos />
                                        }
                                    </IconButton>
                                </Col>
                            </Row>
                            <MenuItem style={{ color: isHovered[0] ? colors.blue : colors.white01 }} onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={() => handleMouseLeave(0)} icon={<IconHome />} onClick={() => changeIndexPage(0)} > Inicio </MenuItem>
                            {
                                usuario.permisos.menuClientes === 1 &&
                                <SubMenu style={{ color: isHovered[1] ? colors.blue : colors.white01, }} onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={() => handleMouseLeave(1)} icon={<Groups />} label="Clientes" >
                                    <MenuItem style={{ border: isHovered[2] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[2] ? colors.blue : colors.white01, backgroundColor: isHovered[2] ? colors.white01 : colors.blue }} onMouseEnter={() => handleMouseEnter(2)} onMouseLeave={() => handleMouseLeave(2)} icon={<List />} onClick={() => changeIndexPage(1)} > Listar </MenuItem>
                                    <MenuItem style={{ border: isHovered[3] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[3] ? colors.blue : colors.white01, backgroundColor: isHovered[3] ? colors.white01 : colors.blue }} onMouseEnter={() => handleMouseEnter(3)} onMouseLeave={() => handleMouseLeave(3)} icon={<PersonAddAlt1 />} onClick={() => { changeIndexPage(2); navigate(`/dashboard/?page=2`); document.getElementById("btn-cliente-nuevo")?.click(); }} > Agregar </MenuItem>
                                </SubMenu>
                            }
                            {
                                usuario.permisos.administraciondeCorreos === 1 &&
                                <MenuItem style={{ border: isHovered[5] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[5] ? colors.blue : colors.white01, backgroundColor: isHovered[5] ? colors.white01 : colors.blue }} onMouseEnter={() => handleMouseEnter(5)} onMouseLeave={() => handleMouseLeave(5)} icon={<Email />} onClick={() => changeIndexPage(3)} > Correos </MenuItem>
                            }

                            {
                                usuario.permisos.administraciondeWhatsApp === 1 &&
                                <SubMenu style={{ color: isHovered[11] ? colors.blue : colors.white01, }} onMouseEnter={() => handleMouseEnter(11)} onMouseLeave={() => handleMouseLeave(11)} icon={<WhatsApp />} label="WhatsApp" >
                                    <MenuItem style={{ border: isHovered[4] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[4] ? colors.blue : colors.white01, backgroundColor: isHovered[4] ? colors.white01 : colors.blue }} onMouseEnter={() => handleMouseEnter(4)} onMouseLeave={() => handleMouseLeave(4)} icon={<Message />} onClick={() => changeIndexPage(4)} > Mandar Mensajes </MenuItem>
                                    <MenuItem style={{ border: isHovered[12] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[12] ? colors.blue : colors.white01, backgroundColor: isHovered[12] ? colors.white01 : colors.blue }} onMouseEnter={() => handleMouseEnter(12)} onMouseLeave={() => handleMouseLeave(12)} icon={<Settings />} onClick={() => changeIndexPage(12)} > Configuración </MenuItem>
                                </SubMenu>
                            }
                            <SubMenu style={{ color: isHovered[7] ? colors.blue : colors.white01, }} onMouseEnter={() => handleMouseEnter(7)} onMouseLeave={() => handleMouseLeave(7)} icon={<Computer />} label="Sistema" >
                                {
                                    usuario.permisos.administraciondeUsuarios === 1 &&
                                    <MenuItem style={{ border: isHovered[8] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[8] ? colors.blue : colors.white01, backgroundColor: isHovered[8] ? colors.white01 : colors.blue }} onMouseEnter={() => handleMouseEnter(8)} onMouseLeave={() => handleMouseLeave(8)} icon={<Group />} onClick={() => changeIndexPage(5)}>Usuarios</MenuItem>
                                }
                                <MenuItem style={{ border: isHovered[9] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[9] ? colors.blue : colors.white01, backgroundColor: isHovered[9] ? colors.white01 : colors.blue }} onMouseEnter={() => handleMouseEnter(9)} onMouseLeave={() => handleMouseLeave(9)} icon={<AccountCircle />} onClick={() => changeIndexPage(6)} >Mi Perfil</MenuItem>
                                <MenuItem style={{ border: isHovered[10] ? `1.5px solid ${colors.blue}` : 'none', color: isHovered[10] ? colors.blue : colors.white01, backgroundColor: isHovered[10] ? colors.white01 : colors.blue }} onMouseEnter={() => handleMouseEnter(10)} onMouseLeave={() => handleMouseLeave(10)} icon={<Logout />} onClick={() => { UsuarioModel.cerrarSesion() }}>Salir</MenuItem>
                            </SubMenu>
                        </Menu>
                    </Sidebar>
                    :
                    <>
                        <Row style={{ marginRight: 0, marginLeft: 0, backgroundColor: colors.blue }} >
                            <Col style={{ textAlign: "right" }} >
                                <Button
                                    style={{ backgroundColor: colors.blue, color: colors.white01, marginTop: 10, marginBottom: 10, borderColor: colors.blue, boxShadow: "none" }}
                                    onClick={() => setIsOpenCanvas(true)}
                                >
                                    <MenuIcon />
                                </Button>
                            </Col>
                        </Row>
                        <Offcanvas direction="end" isOpen={isOpenCanvas} >
                            <OffcanvasHeader
                                close={

                                    <IconButton
                                        style={{ backgroundColor: colors.white01, color: colors.blue, marginTop: 0, marginBottom: 0, borderColor: colors.blue, boxShadow: "none" }}
                                        onClick={() => setIsOpenCanvas(false)}
                                    >
                                        <Close />
                                    </IconButton>
                                }
                            >
                                <img src={imgLogo} alt="/*-" style={{ height: 50 }} />
                            </OffcanvasHeader>
                            <OffcanvasBody style={{ padding: 0 }} >
                                <Sidebar style={{ width: "100%", backgroundColor: colors.white01 }} >
                                    <Menu
                                        style={{ backgroundColor: colors.white01, marginTop: 0, height: "100%" }}
                                    >
                                        <Block height={10} />
                                        <MenuItem style={{ color: colors.blue }} onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={() => handleMouseLeave(0)} icon={<IconHome />} onClick={() => { changeIndexPage(0); setIsOpenCanvas(false); }} > Inicio </MenuItem>
                                        <SubMenu style={{ color: colors.blue }} onMouseEnter={() => handleMouseEnter(3)} onMouseLeave={() => handleMouseLeave(3)} icon={<Groups />} label="Clientes" >
                                            <MenuItem style={{ color: colors.blue }} onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={() => handleMouseLeave(1)} icon={<List />} onClick={() => { changeIndexPage(1); setIsOpenCanvas(false); }} > Listar </MenuItem>
                                            <MenuItem style={{ color: colors.blue }} onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={() => handleMouseLeave(1)} icon={<PersonAddAlt1 />} onClick={() => { changeIndexPage(2); setIsOpenCanvas(false); }} > Agregar </MenuItem>
                                        </SubMenu>

                                        <MenuItem style={{ color: colors.blue }} onMouseEnter={() => handleMouseEnter(5)} onMouseLeave={() => handleMouseLeave(5)} icon={<Email />} onClick={() => { changeIndexPage(3); setIsOpenCanvas(false); }} > Correos </MenuItem>

                                        <SubMenu style={{ color: colors.blue }} onMouseEnter={() => handleMouseEnter(11)} onMouseLeave={() => handleMouseLeave(11)} icon={<WhatsApp />} label="WhatsApp" >
                                            <MenuItem style={{ color: colors.blue }} onMouseEnter={() => handleMouseEnter(4)} onMouseLeave={() => handleMouseLeave(4)} icon={<Message />} onClick={() => { changeIndexPage(4); setIsOpenCanvas(false); }} > Mandar Mensajes </MenuItem>
                                            <MenuItem style={{ color: colors.blue }} onMouseEnter={() => handleMouseEnter(12)} onMouseLeave={() => handleMouseLeave(12)} icon={<Settings />} onClick={() => { changeIndexPage(12); setIsOpenCanvas(false); }} > Configuración </MenuItem>
                                        </SubMenu>

                                        <SubMenu style={{ color: colors.blue }} onMouseEnter={() => handleMouseEnter(3)} onMouseLeave={() => handleMouseLeave(3)} icon={<Computer />} label="Sistema" >
                                            <MenuItem style={{ color: colors.blue }} onMouseEnter={() => handleMouseEnter(4)} onMouseLeave={() => handleMouseLeave(4)} icon={<Group />} onClick={() => { changeIndexPage(5); setIsOpenCanvas(false); }}>Usuarios</MenuItem>
                                            <MenuItem style={{ color: colors.blue }} onMouseEnter={() => handleMouseEnter(5)} onMouseLeave={() => handleMouseLeave(5)} icon={<AccountCircle />} onClick={() => { changeIndexPage(6); setIsOpenCanvas(false); }} >Mi Perfil</MenuItem>
                                            <MenuItem style={{ color: colors.blue }} onMouseEnter={() => handleMouseEnter(6)} onMouseLeave={() => handleMouseLeave(6)} icon={<Logout />} onClick={() => { UsuarioModel.cerrarSesion() }}>Salir</MenuItem>
                                        </SubMenu>
                                    </Menu>
                                </Sidebar>
                            </OffcanvasBody>
                        </Offcanvas>
                    </>
            }
            <div onClick={() => { setCollapsed(true) }} >
                {indexPage === 0 && <Home />}
                {indexPage === 1 && <ListaCliente changeIndexPage={changeIndexPage} />}
                {indexPage === 2 && <InfoCliente changeIndexPage={changeIndexPage} />}
                {indexPage === 3 && <CarcaCorreosIndex />}
                {indexPage === 4 && <WhatsAppPage />}
                {indexPage === 5 && <Usuarios />}
                {indexPage === 6 && <MiPerfil />}
                {indexPage === 12 && <WhatsAppConfig />}
            </div>
        </section>
    );
};


export default Dashboard;