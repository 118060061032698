import ApiConfig from "../config/Api.config";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";
import SolicitudCredito from "../interfaces/SolicitudCredito.interface";

const url = `${ApiConfig.URLBase}/solicitud-credito`

const obtener = async (IdCliente: string): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar/${IdCliente}`,
        metodo: "GET",
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const guardar = async (solicitudCredito: SolicitudCredito): Promise<Response> => {
    const request: Request = {
        url: `${url}/guardar`,
        metodo: "POST",
        data: { solicitudCredito }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const eliminar = async (IdSolicitudCredito: string): Promise<Response> => {
    const request: Request = {
        url: `${url}/eliminar/${IdSolicitudCredito}`,
        metodo: "DELETE"
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const listarMontos = async (): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar-montos`,
        metodo: "GET",
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const listarPlazoCredito = async (): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar-plazo-credito`,
        metodo: "GET",
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const listarFormaPago = async (): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar-forma-pago`,
        metodo: "GET",
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const listarStatus = async (): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar-status`,
        metodo: "GET",
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const SolicitudCreditoService = {
    obtener,
    listarMontos,
    listarPlazoCredito,
    listarFormaPago,
    listarStatus,
    guardar,
    eliminar
};

export default SolicitudCreditoService;