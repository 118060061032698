import { ChangeEvent, KeyboardEvent, FC, useEffect, useState, FocusEvent, SyntheticEvent } from "react";
import DeviceConfig from "../../config/Device.config";
import Device from "../../interfaces/Device.interface";
import colors from "../../config/Colors.config";
import Block from "../../components/Block.component";
import { Button, Col, DropdownItem, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from "reactstrap";
import Correo from "../../interfaces/CarcaCorreos.interface";
import imgTzedakaLogo from "../../assets/img/logoTzedaka.png";
import Response from "../../interfaces/Response.interface";
import CorreosService from "../../services/Correos.service";
import Styles from "../../assets/css/Styles.css";
import Tiempo from "../../tools/Tiempo.tool";
import Components from "../../tools/Components.tool";
import { Close, Search, CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";
import { toast, ToastContainer } from "react-toastify";
import { IconButton } from "@material-ui/core";
import Table, { TableColumn } from 'react-data-table-component';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/system';
import { Tabs } from "@mui/material";

import iconCargandoCorreo from "../../assets/icon/cargandoCorreo.png";
import iconTarjetaFelicitacion from "../../assets/icon/tarjeta-de-felicitacion.png";


const CarcaCorreosIndex: FC = () => {

    const [texto, setTexto] = useState<string>("");
    const [cargaCorreosLista, setCargaCorreosLista] = useState<Correo[]>([]);
    const [hora, setHora] = useState<string>(Tiempo.getCurrentTime());
    const [value, setValue] = useState<string>('1');
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [contador, setContador] = useState<number>(0);
    const [valueProgress, setValueProgress] = useState<number>(0);
    const [labelInfoCargaMasiva, setLabelInfoCargaMasiva] = useState<string>("");

    const [isOverMarcarTodos, setIsOverMarcarTodos] = useState<boolean>(false);
    const [isOverDesmarcarTodos, setIsOverDesmarcarTodos] = useState<boolean>(false);

    const MyTabs = styled(Tabs)(() => ({ '.MuiTabs-indicator': { backgroundColor: colors.blue, color: colors.blue }, }));

    const [data] = useState<Device>(DeviceConfig.data());

    useEffect(() => {
        cargarDatos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cargarDatos = async (): Promise<void> => {
        // listarCorreos(texto);
    }

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const listarCorreos = async (texto: string): Promise<void> => {
        setHora("Cargando correos...");
        const response: Response = await CorreosService.listar(texto);
        const cargaCorreosLista: Correo[] = response.data.value;
        setCargaCorreosLista(cargaCorreosLista);
        setHora(Tiempo.getCurrentTimeFormat12Hour());
    }

    const onChangeMandarCorreoRenovacion = (IdCliente: string) => {
        let updatedCorreos: Correo[] = [];
        if (value === '1') updatedCorreos = cargaCorreosLista.map((correo: Correo) => correo.IdCliente === IdCliente ? { ...correo, mandarCorreoRenovacion: correo.mandarCorreoRenovacion === 1 ? 0 : 1 } : correo);
        if (value === '2') updatedCorreos = cargaCorreosLista.map((correo: Correo) => correo.IdCliente === IdCliente ? { ...correo, mandarCorreoCumpleanos: correo.mandarCorreoCumpleanos === 1 ? 0 : 1 } : correo);
        setCargaCorreosLista(updatedCorreos);

    }

    const guardarDatosCorreo = async (): Promise<void> => {
        const array: Correo[] = [...cargaCorreosLista];
        const response: Response = await CorreosService.cambiar(array);
        if (response.error === false) toast.info("Datos guardados");
        if (response.error === true) toast.error("Error");
    }

    const mandarCorreoMasivoRenovacion = async (): Promise<void> => {
        let array: Correo[] = [];
        let tipo: string = ""
        if (value === '1') {
            tipo = "RENOVACION"
            array = [...cargaCorreosLista.filter(correo => correo.mandarCorreoRenovacion === 1)];
        } else {
            tipo = "CUMPLEANOS"
            array = [...cargaCorreosLista.filter(correo => correo.mandarCorreoCumpleanos === 1)];
        }

        let cont: number = 0;
        let valueProgress: number;
        setContador(cont);
        setValueProgress(0);
        setLabelInfoCargaMasiva(`Preparando el envió masivo para ${array.length} correo(s)`);
        await Tiempo.sleep(2);

        for (let i = 0; i < array.length; i++) {
            const correo: Correo = array[i];
            //await Tiempo.sleep(1.5);
            cont = cont + 1;
            valueProgress = (cont * 100) / array.length
            setValueProgress(valueProgress);
            await CorreosService.mandar(correo, tipo);
            // await WhatsAppService.mandarMensaje(correo.telefono, tipo)
            setContador(cont);
        }
    }

    const marcarDesmarcar = (newValue: number): void => {
        if (cargaCorreosLista.length > 0) {
            let updatedCorreos: Correo[] = [];
            if (value === '1') updatedCorreos = cargaCorreosLista.map((correo: Correo) => { return { ...correo, mandarCorreoRenovacion: newValue } });
            if (value === '2') updatedCorreos = cargaCorreosLista.map((correo: Correo) => { return { ...correo, mandarCorreoCumpleanos: newValue } });
            setCargaCorreosLista(updatedCorreos);
        } else {
            toast.info("Filtra primero a los clientes");
        }

    }

    const columnsClientes: TableColumn<Correo>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > Num. </Label>,
            cell: (correo: Correo, index: number) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{index + 1}</Label>,
            width: "100px"
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > CLIENTE</Label>,
            cell: (correo: Correo) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{correo.nombres}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > CORREO </Label>,
            cell: (correo: Correo) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{correo.correoElectronicoCliente}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > TELÉFONO </Label>,
            cell: (correo: Correo) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{correo.telefono}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} >  </Label>,
            cell: (correo: Correo) =>
                <>
                    <IconButton
                        style={{ color: colors.blue }}
                        onClick={() => {
                            onChangeMandarCorreoRenovacion(correo.IdCliente);
                        }}
                    >
                        {value === '1' && (correo.mandarCorreoRenovacion === 1 ? <CheckBox /> : <CheckBoxOutlineBlank />)}
                        {value === '2' && (correo.mandarCorreoCumpleanos === 1 ? <CheckBox /> : <CheckBoxOutlineBlank />)}
                    </IconButton>
                    <Label
                        style={{
                            color: value === '1' ? (correo.mandarCorreoRenovacion === 1 ? colors.blue : colors.grey) : (correo.mandarCorreoCumpleanos === 1 ? colors.blue : colors.grey),
                            fontWeight: "bold", fontSize: 16, whiteSpace: 'normal', marginTop: 10, marginLeft: 15
                        }}
                    >
                        {value === '1' && (correo.mandarCorreoRenovacion === 1 ? "Mandar" : "No Mandar")}
                        {value === '2' && (correo.mandarCorreoCumpleanos === 1 ? "Mandar" : "No Mandar")}
                    </Label>
                </>
            ,
        },
    ]

    return (
        <>
            <ToastContainer  position="bottom-right" limit={3} autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
            <main style={{ width: "100%", minHeight: "100vh", height: "100vh", maxHeight: "auto", backgroundColor: colors.white01 }} >
                <section style={{ marginLeft: data.isDesktop ? 80 : 0 }} >

                    <Block height={"2vh"} />
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col>
                            <Label style={{ fontSize: 30, }} >
                                ENVIÓ DE CORREOS
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <img src={imgTzedakaLogo} alt="/*-" style={{ height: 50, marginRight: 15 }} />
                        </Col>
                    </Row>
                    <Block height={"1vh"} />
                    <DropdownItem divider />
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 0 }} >
                        <Col style={{ minWidth: "50%", width: "50%", maxWidth: "50%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Buscar Cliente
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="textBuscar"
                                        type="text"
                                        placeholder="Nombres, Apellido Paterno, Apellido Materno"
                                        value={texto}
                                        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                            setTexto(value)
                                        }}
                                        onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                            if (key === "Enter") listarCorreos(texto);
                                        }}
                                        onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                            event.target.select();
                                        }}
                                        style={Styles.input}
                                    />
                                    <InputGroupText
                                        style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white01, cursor: "pointer" }}
                                        onClick={() => {
                                            setTexto("");
                                            Tiempo.sleep(1).then(() => setCargaCorreosLista([]));
                                            Components.focus("textBuscar");

                                        }}
                                    >
                                        <Close />
                                    </InputGroupText>
                                    <InputGroupText
                                        style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white01, cursor: "pointer" }}
                                        onClick={() => {
                                            // listarClientes(textBuscar);
                                            // Components.focus("textBuscar");
                                            if (texto.length > 0) {
                                                listarCorreos(texto);
                                            } else {
                                                toast.warning("No has especificado la búsqueda");
                                                Components.focus("textBuscar");
                                            }
                                        }}
                                    >
                                        <Search />
                                    </InputGroupText>
                                </InputGroup>
                                <Label
                                    style={{ marginTop: 5, color: colors.blue, cursor: "pointer", textDecorationLine: "underline" }}
                                    onClick={() => listarCorreos("")}
                                >
                                    Listar a todos los cientes
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <IconButton
                                title={"Actualizar Tabla de Clientes"}
                                style={{ marginRight: 20,  color: colors.blue }}
                                onClick={() => setCargaCorreosLista([])}
                            >
                                <Close />
                            </IconButton>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 5 }} >
                        <Col>
                            <Label style={{ fontWeight: "bold" }} >
                                Ultima Actualización: {hora}
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <Button
                                style={{ ...Styles.button, width: 120 }}
                                onClick={() => setIsOpenModal(true)}
                            >
                                Mandar
                            </Button>
                            <Button
                                style={{ ...Styles.button, width: 120, marginLeft: 15 }}
                                onClick={() => guardarDatosCorreo()}
                            >
                                Guardar
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                        <Col style={{ textAlign: "right" }} >
                            <Label
                                style={{ marginRight: 15, color: colors.blue, textDecorationLine: isOverDesmarcarTodos ? "underline" : "", cursor: "pointer" }}
                                onClick={() => marcarDesmarcar(0)}
                                onMouseOver={() => setIsOverDesmarcarTodos(true)}
                                onMouseLeave={() => setIsOverDesmarcarTodos(false)}
                            >
                                Desmarcar Todos
                            </Label>
                            <Label
                                style={{ marginLeft: 15, color: colors.blue, textDecorationLine: isOverMarcarTodos ? "underline" : "", cursor: "pointer" }}
                                onClick={() => marcarDesmarcar(1)}
                                onMouseOver={() => setIsOverMarcarTodos(true)}
                                onMouseLeave={() => setIsOverMarcarTodos(false)}
                            >
                                Marcar Todos
                            </Label>
                        </Col>
                    </Row>

                    
                    <Box sx={{ typography: 'body1', marginRight: 2, marginLeft: 2 }}>
                        <TabContext value={value}>

                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <MyTabs value={value} onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab style={{ color: colors.blue, fontSize: 16 }} label="RENOVACIÓN DE CRÉDITO" value="1" />
                                    <Tab style={{ color: colors.blue, fontSize: 16, marginLeft: 20 }} label="FELICITACIÓN DE CUMPLEAÑOS" value="2" />
                                    {/* <Tab style={{ color: colors.blue, fontSize: 16 }} label="Referencias" value="3" /> */}
                                    {/* <Tab style={{ color: colors.blue, fontSize: 16 }} label="Imágenes" value="4" /> */}
                                </MyTabs>
                            </Box>

                            <TabPanel value="1" style={{ padding: 0 }} >
                                <Block height={10} />
                                {
                                    cargaCorreosLista.length === 0 ?
                                        <center>
                                            <Block height={data.isDesktop ? 10 : 0} />
                                            <img src={iconCargandoCorreo} alt="/*-" style={{ height: "30vh" }} />
                                        </center>
                                        :
                                        <div style={{ height: "50vh", overflowY: "scroll" }} >
                                            <Table
                                                // title={<Label>{cantidadClientes}</Label>}
                                                columns={columnsClientes}
                                                data={cargaCorreosLista}
                                            //fixedHeader
                                            />
                                        </div>
                                }

                            </TabPanel>

                            <TabPanel value="2" style={{ padding: 0 }} >
                                <Block height={10} />
                                {
                                    cargaCorreosLista.length === 0 ?
                                        <center>
                                            <Block height={data.isDesktop ? 10 : 0} />
                                            <img src={iconTarjetaFelicitacion} alt="/*-" style={{ height: "30vh" }} />
                                        </center>
                                        :
                                        <div style={{ height: "50vh", overflowY: "scroll" }} >
                                            <Table
                                                // title={<Label>{cantidadClientes}</Label>}
                                                columns={columnsClientes}
                                                data={cargaCorreosLista}
                                            //fixedHeader
                                            />
                                        </div>
                                }

                            </TabPanel>

                        </TabContext>
                    </Box>

                </section>
            </main>
            <Modal
                isOpen={isOpenModal}
                style={{ fontFamily: "Montserrat", maxWidth: "50%" }}
                onOpened={() => {
                    setContador(0);
                    setValueProgress(0);
                    let array: Correo[] = [];
                    if (value === '1') {
                        array = [...cargaCorreosLista.filter(correo => correo.mandarCorreoRenovacion === 1)];
                    } else {
                        array = [...cargaCorreosLista.filter(correo => correo.mandarCorreoCumpleanos === 1)];
                    }
                    setLabelInfoCargaMasiva(`Preparando el envió masivo para ${array.length} correo(s)`);
                }}
                onClosed={() => { setContador(0); setValueProgress(0); }}
            >
                <ModalHeader
                    style={{ backgroundColor: colors.blue }}
                    close={
                        <Col style={{ textAlign: "right" }} >
                            <IconButton
                                style={{ backgroundColor: colors.white01, color: colors.blue }}
                                onClick={() => setIsOpenModal(false)}
                            >
                                <Close />
                            </IconButton>
                        </Col>
                    }
                >
                    <Label style={{ color: colors.white01, fontSize: 24 }} >
                        Configuración de envió masivo de correo
                    </Label>
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col>
                            {labelInfoCargaMasiva}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {contador}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Progress
                                className="my-2"
                                value={valueProgress}
                            >
                                {valueProgress === 100 ? valueProgress : valueProgress.toFixed(2)} %
                            </Progress>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button
                        onClick={() => {
                            mandarCorreoMasivoRenovacion();
                        }}
                        style={{ ...Styles.button, width: "auto" }}
                    >
                        Mandar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default CarcaCorreosIndex;