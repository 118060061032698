import ApiConfig from "../config/Api.config";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";

const url = `${ApiConfig.URLBase}/ciudades`

const listar = async (IdEstado: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar`,
        metodo: "POST",
        data: { IdEstado }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const CiudadService = {
    listar
};

export default CiudadService;