import ApiConfig from "../config/Api.config";
import Usuario from "../interfaces/Usuario.interface";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";
import UsuarioModel from "../models/Usuario.model";

const url = `${ApiConfig.URLBase}/usuarios`

const login = async (usuario: Usuario): Promise<Response> => {
    usuario = {
        ...usuario,
        password: UsuarioModel.hashearPassword(usuario.password)
    }
    const request: Request = {
        url: `${url}/login`,
        metodo: "POST",
        data: { username: usuario.username, password: usuario.password }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};


const getUsuario = async (IdUsuario: number) => {
    const request: Request = {
        url: `${url}/${IdUsuario}`,
        metodo: "GET",
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
};

const listar = async (texto: string, IdStatus: number) => {
    const request: Request = {
        url: `${url}/listar`,
        metodo: "POST",
        data: { texto, IdStatus }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
};

const guardar = async (usuario: Usuario) => {
    usuario = {
        ...usuario,
        password: UsuarioModel.hashearPassword(usuario.password)
    }
    const request: Request = {
        url: `${url}/guardar`,
        metodo: "POST",
        data: { usuario }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
};

const eliminar = async (IdUsuario: number) => {
    const request: Request = {
        url: `${url}/eliminar/${IdUsuario}`,
        metodo: "DELETE",
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
};

const cambiarPassword = async (IdUsuario: number, password: string) => {
    password = UsuarioModel.hashearPassword(password)
    const request: Request = {
        url: `${url}/cambiar-password`,
        metodo: "POST",
        data: { IdUsuario, password }
    }
    const response = await ApiConfig.executeRequest(request);
    return response;
};

const UsuarioService = {
    login,
    getUsuario,
    listar,
    guardar,
    eliminar,
    cambiarPassword,
};

export default UsuarioService;