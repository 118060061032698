

const colors = {
    white01: "#FFFFFF",
    white02: "#FEFEFE",
    white03: "#F4F4F4",
    green01: "#27A243",
    green02: "#089041",
    blue: "#0F6192",
    link: "#089DD7",
    blueSlate: "#A2B3C3",
    // red: "#F4493C",
    red:"#E5252A",
    warning: "#F5C42F",
    black: "#000000",
    grey:"#B3B3B3",
    pdf:{
        grey01:"#323639",
        grey02:"#525659"
    }
};


export default colors;