import ApiConfig from "../config/Api.config";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";

const url = `${ApiConfig.URLBase}/localidades`

const listar = async (IdCiudad: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar`,
        metodo: "POST",
        data: { IdCiudad }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const LocalidadService = {
    listar
};

export default LocalidadService;