import Ciudad from "../interfaces/Ciudad.interface"
import EstadoModel from "./Estado.model"
import PaisModel from "./Pais.model"

const getEstructura = (): Ciudad => {
    const ciudad: Ciudad = {
        IdCiudad: 0,
        pais: PaisModel.getEstructura(),
        estado: EstadoModel.getEstructura(),
        ciudad: ""
    }
    return ciudad;
}



const CiudadModel = {
    getEstructura,
}

export default CiudadModel