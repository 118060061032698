import ApiConfig from "../config/Api.config";
import Cliente from "../interfaces/Cliente.interface";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";

const url = `${ApiConfig.URLBase}/clientes`

const guardar = async (cliente: Cliente): Promise<Response> => {
    const request: Request = {
        url: `${url}/guardar`,
        metodo: "POST",
        data: { cliente }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const listar = async (texto: string): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar`,
        metodo: "POST",
        data: { texto }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const obtener = async (IdCliente: string): Promise<Response> => {
    const request: Request = {
        url: `${url}/obtener`,
        metodo: "POST",
        data: { IdCliente }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const obtenerContactos = async (texto: string): Promise<Response> => {    
    const request: Request = {
        url: `${url}/listar/whatsApp/contactos`,
        metodo: "POST",
        data: { texto }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};



const ClienteService = {
    guardar,
    listar,
    obtener,
    obtenerContactos,
};

export default ClienteService;