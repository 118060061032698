import { FC, useEffect, useState, FocusEvent, ChangeEvent, KeyboardEvent, SyntheticEvent } from "react";
import { toast, ToastContainer } from "react-toastify";
import Block from "../components/Block.component";
import Device from "../interfaces/Device.interface";
import DeviceConfig from "../config/Device.config";
import colors from "../config/Colors.config";
import { Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button, DropdownItem, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import imgTzedakaLogo from "../assets/img/logoTzedaka.png";
import iconNoUser from "../assets/icon/quitar-usuario.png"
import UsuarioService from "../services/Usuario.service";
import Components from "../tools/Components.tool";
import Styles from "../assets/css/Styles.css";
import { Add, Cached, Close, Delete, Edit, LockOpen, Search, Lock, CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import Usuario from "../interfaces/Usuario.interface";
import Table, { TableColumn } from 'react-data-table-component';
import { IconButton } from "@material-ui/core";
import UsuarioModel from "../models/Usuario.model";
import Swal from "sweetalert2";

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/system';
import { Tabs } from "@mui/material";
import Tiempo from "../tools/Tiempo.tool";
const Usuarios: FC = () => {


    const [texto, setTexto] = useState<string>("");
    const [IdStatus/*, setIdStatus*/] = useState<number>(0);
    const [usuariosLista, setUsuariosLista] = useState<Usuario[]>([]);
    const [isOpenModalUsuario, setIsOpenModalUsuario] = useState<boolean>(false);
    const [isOpenModalCambiarPassword, setIsOpenModalCambiarPassword] = useState<boolean>(false);
    const [verPassword, setVerPassword] = useState<boolean>(false);
    const [usuario, setUsuario] = useState<Usuario>(UsuarioModel.getEstructura());
    const [isOverCambiarPassword, setIsOverCambiarPassword] = useState<boolean>(false);
    const [hora, setHora] = useState<string>(Tiempo.getCurrentTime());
    const [password, setPassword] = useState<string>("");
    const [arrayErroresUsuario, setArrayErroresUsuario] = useState<string[]>([]);
    const [isOpenModalUsuarioErrores, setIsOpenModalUsuarioErrores] = useState<boolean>(false);
    const [value, setValue] = useState<string>('1');
    const MyTabs = styled(Tabs)(() => ({ '.MuiTabs-indicator': { backgroundColor: colors.blue, color: colors.blue }, }));
    const [myId, setMyId] = useState<number>(UsuarioModel.getIdUsuario());
    const [data] = useState<Device>(DeviceConfig.data());
    const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);
    const [correoValido, setCorreoValido] = useState<boolean>(false);

    useEffect(() => {
        listarUsuario("", 0);
        setMyId(UsuarioModel.getIdUsuario())
    }, []);

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const getColor = (index: number) => {
        const arrayColors: string[] = ["", colors.green01, colors.grey, colors.red];
        return arrayColors[index]
    }

    const listarUsuario = async (texto: string, IdStatus: number): Promise<void> => {
        setHora("Cargando usuarios...");
        const response = await UsuarioService.listar(texto, IdStatus);
        const usuariosLista: Usuario[] = response.data.value;
        setUsuariosLista([...usuariosLista.filter((usuario: Usuario) => usuario.IdUsuario !== 1)]);
        setHora(Tiempo.getCurrentTimeFormat12Hour());
    }

    const validarUsuario = (): boolean => {
        const { IdUsuario, nombreCompleto, password, username, correo, status: { IdStatus } } = usuario;
        let arrayErroresUsuario: string[] = [];
        let value: boolean = true;

        if (nombreCompleto.length === 0) { value = false; arrayErroresUsuario.push("Falta especificar el nombre de la persona"); }
        if (username.length === 0) { value = false; arrayErroresUsuario.push("Falta especificar el nombre del usuario (username)"); }
        // if (correo.length === 0) { value = false; arrayErroresUsuario.push("Falta especificar el nombre del usuario (username)"); }
        if (!correoValido && correo.length > 0) { value = false; arrayErroresUsuario.push(`Este correo no es valido (${usuario.correo}), debe de terminar con "@tzedaka.com.mx"`); }
        if (IdStatus === 0) { value = false; arrayErroresUsuario.push("Falta especificar el estatus del usuario"); }
        if (IdUsuario === 0) if (password.length === 0) { value = false; arrayErroresUsuario.push("Falta especificar la contraseña (password)"); }
        setArrayErroresUsuario(arrayErroresUsuario);

        return value;
    }

    const guardarUsuario = async (): Promise<void> => {
        const value: boolean = validarUsuario();
        if (value) {
            const response = await UsuarioService.guardar(usuario);
            const vIdUsuario: number = response.data.value;
            setUsuario((prev: Usuario) => ({ ...prev, IdUsuario: vIdUsuario }));
            listarUsuario(texto, IdStatus);
            toast.success("Datos Guardados");
        } else {
            setIsOpenModalUsuarioErrores(true);
            toast.warning("Faltan datos por especificar");
        }
    }

    const eliminarUsuario = async (usuario: Usuario): Promise<void> => {
        Swal.fire({
            icon: "question",
            title: "Eliminar Usuario",
            text: `¿Quieres eliminar a este usuario (${usuario.nombreCompleto})?`,
            showConfirmButton: true,
            confirmButtonColor: colors.blue,
            denyButtonColor: colors.blue,
            cancelButtonText: "No",
            confirmButtonText: "Si",
            reverseButtons: true,
            showCancelButton: true,
            timer: 10000,
            timerProgressBar: true,
            // footer: ` <label> <i> No podrás recuperar la cuenta actualmente vinculada </i> </label>`
        }).then(async (response) => {
            if (response.isConfirmed) {
                await UsuarioService.eliminar(usuario.IdUsuario);
                listarUsuario(texto, IdStatus);
                setIsOpenModalUsuario(false);
            }
        })
    }

    const columnsUsuario: TableColumn<Usuario>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > Num. </Label>,
            cell: (usuario: Usuario, index: number) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{index + 1}</Label>,
            width: "100px"
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > NOMBRE COMPLETO</Label>,
            cell: (usuario: Usuario) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{usuario.nombreCompleto}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} >NOMBRE DE USUARIO</Label>,
            cell: (usuario: Usuario) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{usuario.username}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} >CORREO</Label>,
            cell: (usuario: Usuario) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{usuario.correo}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} >ESTATUS</Label>,
            cell: (usuario: Usuario) => <Label style={{ color: getColor(usuario.status.IdStatus), fontSize: 16, whiteSpace: 'normal', fontWeight: "bold" }} >{usuario.status.descripcion}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} ></Label>,
            cell: (usuario: Usuario) =>
                myId !== usuario.IdUsuario ?
                    <>
                        <IconButton
                            style={{ color: colors.red }}
                            onClick={() => eliminarUsuario(usuario)}
                        >
                            <Delete />
                        </IconButton>
                        <IconButton
                            style={{ color: colors.blue }}
                            onClick={() => {
                                setUsuario(usuario);
                                setIsOpenModalUsuario(true);
                                setCorreoValido(UsuarioModel.validarCorreo(usuario.correo));
                            }}
                        >
                            <Edit />
                        </IconButton>
                    </>
                    :
                    <div>
                        {/* <Button
                            id="Popover1"
                            onClick={() => setIsOpenPopover(!isOpenPopover)}
                            style={{ backgroundColor: "#5C636A", borderColor: "#5C636A", color: colors.white01, boxShadow: "none", display: "flex", alignItems: "center", justifyContent: "center", }}
                        >
                            Acciones deshabilitadas <HelpOutline style={{ width: 20, marginLeft: 7 }} />
                        </Button> */}
                        <IconButton
                            id="Popover1"
                            style={{ color: colors.grey }}
                            onClick={() => setIsOpenPopover(!isOpenPopover)}
                        >
                            <Delete />
                        </IconButton>
                        <IconButton
                            id="Popover1"
                            style={{ color: colors.grey }}
                            onClick={() => setIsOpenPopover(!isOpenPopover)}
                        >
                            <Edit />
                        </IconButton>
                        <Popover
                            flip
                            target="Popover1"
                            // style={{ width: 300 }}
                            isOpen={isOpenPopover}

                        >
                            <PopoverHeader  >
                                <Row>
                                    <Col style={{ minWidth: "80%", width: "80%", maxWidth: "80%" }} >
                                        <Label style={{ marginTop: 15 }} >
                                            Acciones deshabilitadas
                                        </Label>
                                    </Col>
                                    <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }} >
                                        <IconButton
                                            style={{ backgroundColor: "transparent", color: colors.black }}
                                            onClick={() => {
                                                setIsOpenPopover(false);
                                            }}
                                        >
                                            <Close />
                                        </IconButton>
                                    </Col>
                                </Row>
                            </PopoverHeader>
                            <PopoverBody style={{ textAlign: "justify" }} >
                                Para actualizar tus datos, utiliza la sección de tu perfil. Si deseas eliminar este usuario, por favor inicia sesión con una cuenta que tenga los permisos necesarios.
                            </PopoverBody>
                        </Popover>
                    </div>
            ,
        },
    ]

    return (
        <>
            <ToastContainer limit={3} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
            <main style={{ width: "100%", minHeight: "100vh", height: "100vh", maxHeight: "auto", backgroundColor: colors.white01 }}  >
                <section style={{ marginLeft: data.isDesktop ? 80 : 0 }} >
                    <Block height={"2vh"} />
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col>
                            <Label style={{ fontSize: 30, }} >
                                USUARIOS DEL SISTEMA
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <img src={imgTzedakaLogo} alt="/*-" style={{ height: 50, marginRight: 15 }} />
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 50 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Buscar usuario
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="textBuscar"
                                        type="text"
                                        placeholder="Nombres, Apellido Paterno, Apellido Materno"
                                        value={texto}
                                        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                            setTexto(value)
                                        }}
                                        onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                            if (key === "Enter") listarUsuario(texto, IdStatus);
                                        }}
                                        onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                            event.target.select();
                                        }}
                                        style={{ ...Styles.input, width: "80%", borderBottomColor: colors.blue, }}
                                    />
                                    <InputGroupText
                                        style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white01, cursor: "pointer" }}
                                        onClick={() => {
                                            setTexto("");
                                            // Tiempo.sleep(1).then(() => setCargaCorreosLista([]));
                                            Components.focus("textBuscar");

                                        }}
                                    >
                                        <Close />
                                    </InputGroupText>
                                    <InputGroupText
                                        style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white01, cursor: "pointer" }}
                                        onClick={() => {
                                            // listarClientes(textBuscar);
                                            // Components.focus("textBuscar");
                                            if (texto.length > 0) {
                                                listarUsuario(texto, IdStatus);
                                            } else {
                                                toast.warning("No has especificado la búsqueda");
                                                Components.focus("textBuscar");
                                            }
                                        }}
                                    >
                                        <Search />
                                    </InputGroupText>
                                </InputGroup>
                                <Label
                                    style={{ marginTop: 5, color: colors.blue, cursor: "pointer", textDecorationLine: "underline" }}
                                    onClick={() => listarUsuario("", 0)}
                                >
                                    Listar a todos los usuario
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <Block height={30} />
                            <IconButton
                                style={{ marginRight: 15, color: colors.blue }}
                                onClick={() => { setIsOpenModalUsuario(true); setUsuario(UsuarioModel.getEstructura()); }}
                            >
                                <Add />
                            </IconButton>
                            <IconButton
                                style={{ color: colors.blue }}
                                onClick={() => listarUsuario(texto, IdStatus)}
                            >
                                <Cached />
                            </IconButton>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 5 }} >
                        <Col>
                            <Label style={{ fontWeight: "bold" }} >
                                Ultima Actualización: {hora}
                            </Label>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                        <Table
                            // title={<Label>{cantidadClientes}</Label>}
                            columns={columnsUsuario}
                            data={[...usuariosLista.filter(usuario => usuario.status.IdStatus !== 3)]}
                            noDataComponent="Tabla vacía"
                        //fixedHeader
                        />
                    </Row>


                </section>
                <Modal
                    isOpen={isOpenModalUsuario}
                    style={{ fontFamily: "Montserrat", maxWidth: "40%" }}
                    onOpened={() => {
                        Components.focus("usuario.nombreCompleto");
                    }}
                >
                    <ModalHeader
                        style={{ backgroundColor: colors.blue }}
                        close={
                            <IconButton
                                style={{ backgroundColor: colors.white01, color: colors.blue }}
                                onClick={() => {
                                    setIsOpenModalUsuario(false);
                                }}
                            >
                                <Close />
                            </IconButton>
                        }
                    >
                        <Label style={{ color: colors.white01 }} >Datos del Usuario</Label>
                    </ModalHeader>

                    <ModalBody  >
                        {/* style={{ minHeight: "65vh", height: "65vh", maxHeight: "65vh", overflowY: "auto" }} */}
                        <Box sx={{ typography: 'body1', marginRight: 2, marginLeft: 2, }}>

                            <TabContext value={value}>

                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <MyTabs value={value} onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab style={{ color: colors.blue, fontSize: 16 }} label="DATOS GENERALES" value="1" />
                                        <Tab style={{ color: colors.blue, fontSize: 16, marginLeft: 20 }} label="ROLES Y PERMISOS" value="2" />

                                    </MyTabs>
                                </Box>

                                <TabPanel value="1" style={{ padding: 0, minHeight: "55vh", height: "55vh", maxHeight: "55vh", overflowY: "auto" }} >
                                    <Block height={10} />
                                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                                        <Col>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 18, }} >
                                                    Nombre completo
                                                </Label>
                                                <Input
                                                    id="usuario.nombreCompleto"
                                                    type="text"
                                                    value={usuario.nombreCompleto}
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setUsuario((prev: Usuario) => ({ ...prev, nombreCompleto: value }));
                                                    }}
                                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                        if (key === "Enter") Components.focus("usuario.username");
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                    style={{ ...Styles.input, width: "100%", borderBottomColor: colors.blue, }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                                        <Col>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 18, }} >
                                                    Nombre de Usuario
                                                </Label>
                                                <Input
                                                    id="usuario.username"
                                                    type="text"
                                                    value={usuario.username}
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setUsuario((prev: Usuario) => ({ ...prev, username: value }));
                                                    }}
                                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                        if (key === "Enter") Components.focus(usuario.IdUsuario === 0 ? "usuario.password" : "usuario.correo");
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                    style={{ ...Styles.input, width: "100%", borderBottomColor: colors.blue, }}
                                                />
                                                {
                                                    usuario.IdUsuario !== 0 &&
                                                    <Label
                                                        style={{ color: colors.blue, textDecorationLine: isOverCambiarPassword ? "underline" : "", cursor: "pointer" }}
                                                        onClick={() => { setIsOpenModalCambiarPassword(true); }}
                                                        onMouseOver={() => setIsOverCambiarPassword(true)}
                                                        onMouseLeave={() => setIsOverCambiarPassword(false)}
                                                    >
                                                        Cambiar contraseña
                                                    </Label>
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {
                                        usuario.IdUsuario === 0 &&
                                        <Row style={{ marginRight: 0, marginLeft: 0 }} >
                                            <Col>
                                                <FormGroup>
                                                    <Label style={{ color: colors.black, fontSize: 18, }} >
                                                        Contraseña
                                                    </Label>
                                                    <InputGroup>
                                                        <Input
                                                            style={Styles.input}
                                                            value={usuario.password}
                                                            id="usuario.password"
                                                            type={verPassword ? "text" : "password"}
                                                            onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                                                setUsuario((prev: Usuario) => ({ ...prev, password: value }));
                                                            }}
                                                            onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                                if (key === "Enter") Components.focus("usuario.correo");;
                                                            }}
                                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                                event.target.select();
                                                            }}
                                                        />
                                                        <InputGroupText
                                                            style={{ ...Styles.input, backgroundColor: "transparent", cursor: "pointer" }}
                                                            onClick={() => { setVerPassword(!verPassword); Components.focus("password"); }}
                                                        >
                                                            {verPassword ? <LockOpen style={{ color: colors.blue }} /> : <Lock style={{ color: colors.blue }} />}
                                                        </InputGroupText>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    }
                                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                                        <Col>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 18, }} >
                                                    Correo electrónico institucional
                                                </Label>
                                                <Input
                                                    id="usuario.correo"
                                                    type="text"
                                                    value={usuario.correo}
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        const response: boolean = UsuarioModel.validarCorreo(value);
                                                        setCorreoValido(response);
                                                        setUsuario((prev: Usuario) => ({ ...prev, correo: value }));
                                                    }}
                                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                        if (key === "Enter") Components.focus("");
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                    style={{ ...Styles.input, width: "100%", borderBottomColor: colors.blue, }}
                                                />
                                                {
                                                    usuario.correo.length > 0 &&
                                                    <Label
                                                        style={{ color: correoValido ? colors.green01 : colors.red }}
                                                        onClick={() => { setIsOpenModalCambiarPassword(true); }}
                                                        onMouseOver={() => setIsOverCambiarPassword(true)}
                                                        onMouseLeave={() => setIsOverCambiarPassword(false)}
                                                    >
                                                        {correoValido ? "Correo valido" : "Este correo no es valido, debe de terminar con @tzedaka.com.mx"}
                                                    </Label>
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                                        <Col>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 18, }} >
                                                    Estatus
                                                </Label>
                                                <Input
                                                    id="usuario.status.IdStatus"
                                                    type="select"
                                                    value={usuario.status.IdStatus}
                                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setUsuario((prev: Usuario) => ({ ...prev, status: { ...prev.status, IdStatus: +value } }));
                                                    }}
                                                    // onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                    //     if (key === "Enter") Components.focus("usuario.username");
                                                    // }}
                                                    // onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                    //     event.target.select();
                                                    // }}
                                                    style={{ ...Styles.input, width: "100%", borderBottomColor: colors.blue, }}
                                                >
                                                    <option value={0}>Seleccione una opción</option>
                                                    <option value={1}>Activo</option>
                                                    <option value={2}>Bloqueado</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </TabPanel>

                                <TabPanel value="2" style={{ padding: 0, overflowY: "auto", minHeight: "55vh", height: "55vh", maxHeight: "55vh" }} >
                                    <Block height={10} />
                                    {
                                        usuario.IdUsuario === 0 ?
                                            <center>
                                                <img style={{ height: 150, marginTop: 40 }} src={iconNoUser} alt="no data" />
                                                <br />
                                                <Label style={{ marginTop: 35, color: colors.blue, fontSize: 18 }}>Debes de seleccionar o crear un usuario <br /> para poder asignarle permisos</Label>
                                            </center>
                                            :
                                            <>
                                                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                                                    <Col style={{ width: "80%", minWidth: "80%", maxWidth: "80%", }} >
                                                        Menu de Clientes
                                                    </Col>
                                                    <Col style={{ textAlign: "right", width: "20%", minWidth: "20%", maxWidth: "20%", }} >
                                                        <IconButton
                                                            style={{ color: colors.blue }}
                                                            onClick={() => {
                                                                const menuClientes = usuario.permisos.menuClientes === 1 ? 0 : 1;
                                                                setUsuario((prev: Usuario) => ({ ...prev, permisos: { ...prev.permisos, menuClientes } }))
                                                            }}
                                                        >
                                                            {
                                                                usuario.permisos.menuClientes === 1 ? <CheckBox /> : <CheckBoxOutlineBlank />
                                                            }
                                                        </IconButton>
                                                    </Col>
                                                </Row>
                                                <DropdownItem divider />
                                                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                                                    <Col style={{ width: "80%", minWidth: "80%", maxWidth: "80%", }} >
                                                        Administración de Correos
                                                    </Col>
                                                    <Col style={{ textAlign: "right", width: "20%", minWidth: "20%", maxWidth: "20%", }} >
                                                        <IconButton
                                                            style={{ color: colors.blue }}
                                                            onClick={() => {
                                                                const administraciondeCorreos = usuario.permisos.administraciondeCorreos === 1 ? 0 : 1;
                                                                setUsuario((prev: Usuario) => ({ ...prev, permisos: { ...prev.permisos, administraciondeCorreos } }))
                                                            }}
                                                        >
                                                            {
                                                                usuario.permisos.administraciondeCorreos === 1 ? <CheckBox /> : <CheckBoxOutlineBlank />
                                                            }
                                                        </IconButton>
                                                    </Col>
                                                </Row>
                                                <DropdownItem divider />
                                                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                                                    <Col style={{ width: "80%", minWidth: "80%", maxWidth: "80%", }} >
                                                        Administración de WhatsApp
                                                    </Col>
                                                    <Col style={{ textAlign: "right", width: "20%", minWidth: "20%", maxWidth: "20%", }} >
                                                        <IconButton
                                                            style={{ color: colors.blue }}
                                                            onClick={() => {
                                                                const administraciondeWhatsApp = usuario.permisos.administraciondeWhatsApp === 1 ? 0 : 1;
                                                                setUsuario((prev: Usuario) => ({ ...prev, permisos: { ...prev.permisos, administraciondeWhatsApp } }))
                                                            }}
                                                        >
                                                            {
                                                                usuario.permisos.administraciondeWhatsApp === 1 ? <CheckBox /> : <CheckBoxOutlineBlank />
                                                            }
                                                        </IconButton>
                                                    </Col>
                                                </Row>
                                                <DropdownItem divider />
                                                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                                                    <Col style={{ width: "80%", minWidth: "80%", maxWidth: "80%", }} >
                                                        Administración de Usuarios
                                                    </Col>
                                                    <Col style={{ textAlign: "right", width: "20%", minWidth: "20%", maxWidth: "20%", }} >
                                                        <IconButton
                                                            style={{ color: colors.blue }}
                                                            onClick={() => {
                                                                const administraciondeUsuarios = usuario.permisos.administraciondeUsuarios === 1 ? 0 : 1;
                                                                setUsuario((prev: Usuario) => ({ ...prev, permisos: { ...prev.permisos, administraciondeUsuarios } }))
                                                            }}
                                                        >
                                                            {
                                                                usuario.permisos.administraciondeUsuarios === 1 ? <CheckBox /> : <CheckBoxOutlineBlank />
                                                            }
                                                        </IconButton>
                                                    </Col>
                                                </Row>
                                            </>
                                    }
                                </TabPanel>

                            </TabContext>

                        </Box>

                    </ModalBody>

                    <ModalFooter>
                        <Row style={{ marginRight: 0, marginLeft: 0, width: "100%" }} >
                            <Col style={{ textAlign: "left", minWidth: "30%", width: "30%", maxWidth: "30%" }} >
                                {
                                    usuario.IdUsuario !== 0 &&
                                    < Button
                                        onClick={() => {
                                            eliminarUsuario(usuario);
                                        }}
                                        style={{ ...Styles.button, width: 115, backgroundColor: colors.red, color: colors.white01, borderColor: colors.red }}
                                    >
                                        Eliminar
                                    </Button>
                                }
                            </Col>
                            <Col style={{ textAlign: "right", minWidth: "70%", width: "70%", maxWidth: "70%" }} >
                                <Button
                                    id="button.cancelar"
                                    onClick={() => {
                                        setUsuario(UsuarioModel.getEstructura());
                                        Components.focus("usuario.nombreCompleto");
                                    }}
                                    style={{ ...Styles.button, width: 115, backgroundColor: colors.white01, color: colors.blue, borderColor: colors.blue, marginRight: 15 }}
                                >
                                    {usuario.IdUsuario === 0 ? "Cancelar" : "Nuevo"}
                                </Button>
                                <Button
                                    onClick={() => { guardarUsuario(); }}
                                    style={{ ...Styles.button, width: 115 }}
                                >
                                    {usuario.IdUsuario === 0 ? "Guardar" : "Actualizar"}
                                </Button>
                            </Col>
                        </Row>

                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={isOpenModalCambiarPassword}
                    style={{ fontFamily: "Montserrat" }}
                    onOpened={() => {
                        Components.focus("password");
                    }}
                >
                    <ModalHeader
                        style={{ backgroundColor: colors.blue }}
                        close={
                            <IconButton
                                style={{ backgroundColor: colors.white01, color: colors.blue }}
                                onClick={() => {
                                    setIsOpenModalCambiarPassword(false);
                                }}
                            >
                                <Close />
                            </IconButton>
                        }
                    >
                        <Label style={{ color: colors.white01 }} >Cambiar Contraseña</Label>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 18, }} >
                                        Contraseña
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            style={Styles.input}
                                            value={password}
                                            id="password"
                                            type={verPassword ? "text" : "password"}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                setPassword(value);
                                            }}
                                            onKeyDown={async ({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                if (key === "Enter") {
                                                    await UsuarioService.cambiarPassword(usuario.IdUsuario, password);
                                                    toast.info("Contraseña cambiada");
                                                    setIsOpenModalCambiarPassword(false);
                                                }
                                            }}
                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                event.target.select();
                                            }}
                                        />
                                        <InputGroupText
                                            style={{ ...Styles.input, backgroundColor: "transparent", cursor: "pointer" }}
                                            onClick={() => { setVerPassword(!verPassword); Components.focus("password"); }}
                                        >
                                            {verPassword ? <LockOpen style={{ color: colors.blue }} /> : <Lock style={{ color: colors.blue }} />}
                                        </InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={async () => {
                                await UsuarioService.cambiarPassword(usuario.IdUsuario, password);
                                toast.info("Contraseña cambiada");
                                setIsOpenModalCambiarPassword(false);
                            }}
                            style={{ ...Styles.button, width: 115 }}
                        >
                            Guardar
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={isOpenModalUsuarioErrores}
                    style={{ fontFamily: "Montserrat", maxWidth: "35%" }}
                >
                    <ModalHeader
                        style={{ backgroundColor: colors.blue }}
                        close={
                            <IconButton
                                style={{ backgroundColor: colors.white01, color: colors.blue }}
                                onClick={() => {
                                    setIsOpenModalUsuarioErrores(false);
                                }}
                            >
                                <Close />
                            </IconButton>
                        }
                    >
                        <Label style={{ color: colors.white01 }} >No se pudo guardar al usuario</Label>
                    </ModalHeader>
                    <ModalBody>
                        {
                            arrayErroresUsuario.map((error: string, index: number) => {
                                return (
                                    <>
                                        <Row>
                                            <Col>
                                                <Label style={{ fontSize: 18 }} >
                                                    {index + 1}.- {error}
                                                </Label>
                                            </Col>
                                        </Row>
                                        <DropdownItem divider />
                                    </>
                                );
                            })
                        }
                    </ModalBody>
                </Modal>
            </main >
        </>
    );
};

export default Usuarios