import { FC, useEffect, useState, ChangeEvent, KeyboardEvent, FocusEvent } from "react";
import Block from "../components/Block.component";
import colors from "../config/Colors.config";
import { Button, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import imgLogin from "../assets/img/login.svg";
import imgLogo from "../assets/img/logo.png"
import { Lock, LockOpen } from "@material-ui/icons";
import Styles from "../assets/css/Styles.css";
import Usuario from "../interfaces/Usuario.interface";
import UsuarioModel from "../models/Usuario.model";
import Components from "../tools/Components.tool";
import UsuarioService from "../services/Usuario.service";
import Response from "../interfaces/Response.interface";
import { ToastContainer, toast } from "react-toastify";
import Cookies from 'universal-cookie';
import Tiempo from "../tools/Tiempo.tool";
import Device from "../interfaces/Device.interface";
import DeviceConfig from "../config/Device.config";

const cookies = new Cookies();

const Login: FC = () => {

    const [usuario, setUsuario] = useState<Usuario>(UsuarioModel.getEstructura());
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [labelResponse, setLabelResponse] = useState<string>("");
    const [labelColor, setLabelColor] = useState<string>("");
    const [data] = useState<Device>(DeviceConfig.data());

    const login = async (): Promise<void> => {
        const response: Response = await UsuarioService.login(usuario);
        const IdToast = toast.loading("Logueando usuario.");
        if (response.error) {
            toast.update(IdToast, { render: "Error en la petición", type: "error", autoClose: 5000, isLoading: false, closeButton: true });
            setLabelResponse("Error.");
            setLabelColor(colors.red);
        } else {
            if (response.data.value.IdUsuario === 0) {
                toast.update(IdToast, { render: "Acceso Denegado", type: "warning", autoClose: 5000, isLoading: false, closeButton: true });
                Components.focus("username");
                setLabelResponse("Acceso denegado.");
                setLabelColor(colors.warning);
            } else {                
                cookies.set("usuario", response.data.value, { path: '/' });
                cookies.set("permisos", response.data.value, { path: '/' });
                toast.update(IdToast, { render: "Cargando Datos", type: "success", autoClose: 1500, isLoading: false, closeButton: true });
                setLabelResponse("Cargando datos, por favor espere.");
                setLabelColor(colors.green01);
                Tiempo.sleep(1.5).then(() => window.location.href = "/dashboard/?page=0");
            }
        }

    }

    const onChangeUsuario = (name: string, value: string): void => {
        setUsuario((prev: Usuario) => ({ ...prev, [name]: value }));
    }

    const resetUsuario = (): void => {
        setUsuario(UsuarioModel.getEstructura());
        Components.focus("username");
        setLabelResponse("");
        setLabelColor("transparent");
    }

    useEffect(() => {
        Components.focus("username");
    }, []);

    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
            {
                data.isDesktop ?
                    <section style={{ width: "100%", height: "100vh", backgroundColor: colors.white03 }} >
                        <Row style={{ height: "100vh", marginRight: 0, marginLeft: 0, }} >
                            <Col style={{ minWidth: "50%", width: "50%", maxWidth: "50%", height: "100vh" }} >
                                <Row>
                                    <Col style={{ textAlign: "center" }} >
                                        <img src={imgLogin} height={"60%"} style={{ marginTop: "5%" }} alt="/*-" />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: -110 }} >
                                    <Col style={{ textAlign: "center" }} >
                                        <Label style={{ fontWeight: "bold", fontSize: 28, color: colors.blue }} >
                                            CRÉDITOS EN BENEFICIO DE TODOS
                                        </Label>
                                        <br />
                                        <Label style={{ fontWeight: "bold", fontSize: 24, color: colors.blue }} >
                                            ESTAMOS CONSTRUYENDO ALGO FANTÁSTICO
                                        </Label>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{ minWidth: "40%", width: "40%", maxWidth: "40%", padding: 60 }} >
                                <div style={{ backgroundColor: colors.white01, height: "100%", boxShadow: "-10px 0 10px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2)", borderRadius: 12 }} >
                                    <Row >
                                        <Col style={{ textAlign: "center" }} >
                                            <img src={imgLogo} alt="/*-" style={{ height: 160, marginTop: 50 }} />
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: 10 }} >
                                        <Col style={{ textAlign: "center" }} >
                                            <Label style={{ fontSize: 22, color: colors.blue, fontWeight: "bold" }} >
                                                TZEDAKÁ
                                            </Label>
                                            <br />
                                            <Label style={{ fontSize: 16, color: colors.blue, fontWeight: "bold" }} >
                                                Sistema de Solicitud de Crédito 2.0
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: 20, marginRight: "5%", marginLeft: "5%" }} >
                                        <Col>
                                            <FormGroup>
                                                <Label style={{ color: colors.blue, fontSize: 18, fontWeight: "bold" }} >
                                                    Nombre de Usuario / Correo Institucional
                                                </Label>
                                                <InputGroup>
                                                    <Input
                                                        style={Styles.input}
                                                        value={usuario.username}
                                                        id="username"
                                                        name="username"
                                                        onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                                            onChangeUsuario(name, value);
                                                        }}
                                                        onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                            if (key === "Enter") Components.focus("password");
                                                        }}
                                                        onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                            event.target.select();
                                                        }}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: 20, marginRight: "5%", marginLeft: "5%" }} >
                                        <Col>
                                            <FormGroup>
                                                <Label style={{ color: colors.blue, fontSize: 18, fontWeight: "bold" }} >
                                                    Contraseña
                                                </Label>
                                                <InputGroup>
                                                    <Input
                                                        style={Styles.input}
                                                        value={usuario.password}
                                                        id="password"
                                                        name="password"
                                                        type={showPassword ? "text" : "password"}
                                                        onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                                            onChangeUsuario(name, value);
                                                        }}
                                                        onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                            if (key === "Enter") login();
                                                        }}
                                                        onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                            event.target.select();
                                                        }}
                                                    />
                                                    <InputGroupText
                                                        style={{ ...Styles.input, backgroundColor: "transparent", cursor: "pointer" }}
                                                        onClick={() => { setShowPassword(!showPassword); Components.focus("password"); }}
                                                    >
                                                        {showPassword ? <LockOpen style={{ color: colors.blue }} /> : <Lock style={{ color: colors.blue }} />}
                                                    </InputGroupText>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: 30, marginRight: "5%", marginLeft: "5%" }} >
                                        <Col>
                                            <Button
                                                style={{ ...Styles.button, backgroundColor: colors.white01, borderColor: colors.blue, color: colors.blue }}
                                                onClick={() => resetUsuario()}
                                            >
                                                Cancelar
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                style={{ ...Styles.button }}
                                                onClick={() => login()}
                                            >
                                                Iniciar Sesión
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: 30, marginRight: "5%", marginLeft: "5%" }} >
                                        <Col>
                                            <Label style={{ fontSize: 22, color: labelColor, fontWeight: "bold" }} >
                                                {labelResponse}
                                            </Label>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </section >
                    :
                    <section style={{ width: "100%", height: "100vh", backgroundColor: colors.blue }} >
                        <div style={{ height: "30%", backgroundColor: colors.blue, width: "100%" }} >
                            <Block height={"3vh"} />
                            <Row>
                                <Col style={{ textAlign: "center" }} >
                                    <img src={imgLogo} alt="/*-" style={{ height: 120 }} />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 10 }} >
                                <Col style={{ textAlign: "center" }} >
                                    <Label style={{ fontWeight: "bold", fontSize: 20, color: colors.white01 }} >
                                        TZEDAKÁ
                                    </Label>
                                </Col>
                            </Row>
                        </div>
                        <div style={{ height: "70%", backgroundColor: colors.white01, padding: 15, borderRadius: "30px 30px 0px 0px" }} >
                            <Row style={{ marginTop: 50, marginRight: "1%", marginLeft: "1%" }} >
                                <Col>
                                    <FormGroup>
                                        <Label style={{ color: colors.blue, fontSize: 18, fontWeight: "bold" }} >
                                            Nombre de Usuario
                                        </Label>
                                        <InputGroup>
                                            <Input
                                                style={Styles.input}
                                                value={usuario.username}
                                                id="username"
                                                name="username"
                                                onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                                    onChangeUsuario(name, value);
                                                }}
                                                onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                    if (key === "Enter") Components.focus("password");
                                                }}
                                                onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                    event.target.select();
                                                }}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 10, marginRight: "1%", marginLeft: "1%" }} >
                                <Col>
                                    <FormGroup>
                                        <Label style={{ color: colors.blue, fontSize: 18, fontWeight: "bold" }} >
                                            Contraseña
                                        </Label>
                                        <InputGroup>
                                            <Input
                                                style={Styles.input}
                                                value={usuario.password}
                                                id="password"
                                                name="password"
                                                type={showPassword ? "text" : "password"}
                                                onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                                    onChangeUsuario(name, value);
                                                }}
                                                onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                    if (key === "Enter") login();
                                                }}
                                                onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                    event.target.select();
                                                }}
                                            />
                                            <InputGroupText
                                                style={{ ...Styles.input, backgroundColor: "transparent", cursor: "pointer" }}
                                                onClick={() => { setShowPassword(!showPassword); Components.focus("password"); }}
                                            >
                                                {showPassword ? <LockOpen style={{ color: colors.blue }} /> : <Lock style={{ color: colors.blue }} />}
                                            </InputGroupText>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 30, marginRight: "1%", marginLeft: "1%", padding: 0 }} >
                                <Col>
                                    <Button
                                        style={{ ...Styles.button, height: 50 }}
                                        onClick={() => login()}
                                    >
                                        Iniciar Sesión
                                    </Button>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 30, marginRight: "1%", marginLeft: "1%", padding: 0 }} >
                                <Col>
                                    <Button
                                        style={{ ...Styles.button, backgroundColor: colors.white01, borderColor: colors.blue, color: colors.blue, height: 50 }}
                                        onClick={() => resetUsuario()}
                                    >
                                        Cancelar
                                    </Button>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 15, marginRight: "5%", marginLeft: "5%" }} >
                                <Col>
                                    <Label style={{ fontSize: 22, color: labelColor, fontWeight: "bold" }} >
                                        {labelResponse}
                                    </Label>
                                </Col>
                            </Row>
                        </div>
                    </section>
            }

        </>
    );
};

export default Login;