import NivelEstudio from "../interfaces/NivelEstudio.interface"

const getEstructura = (): NivelEstudio => {
    const nivelEstudio: NivelEstudio = {
        IdNivelEstudio: 0,
        nivelEstudio: ""
    };
    return nivelEstudio;
}

const NivelEstudioModel = {
    getEstructura
}

export default NivelEstudioModel