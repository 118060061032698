import { FC } from 'react';
import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import SolicitudCredito from '../interfaces/SolicitudCredito.interface';
import Cliente from '../interfaces/Cliente.interface';
import colors from '../config/Colors.config';
import { Col, Row } from 'reactstrap';

import logo from "../assets/img/logoTzedaka.png";
import Moneda from '../tools/Moneda.tool';
import Fecha from '../tools/Fecha.tool';
import ClienteModel from '../models/Cliente.model';
import Tiempo from '../tools/Tiempo.tool';
// import Cookies from "universal-cookie";

// const cookies = new Cookies();

const SolicitudCreditoPdf: FC<{ solicitudCredito: SolicitudCredito, cliente: Cliente, nombreUsuario: string }> = ({ solicitudCredito, cliente, nombreUsuario }) => {

    // const styles = StyleSheet.create({
    //     page: { padding: 30, fontSize: 12 },
    //     section: { marginBottom: 10 },
    //     header: { fontSize: 18, marginBottom: 20, textAlign: 'center' },
    //     label: { fontSize: 10, fontWeight: 'bold' },
    //     value: { marginBottom: 5 },
    //     signature: { marginTop: 50, textAlign: 'center', fontSize: 12 },
    //     img: { width: 100, height: 100, marginBottom: 10 }
    // });

    // const styles = StyleSheet.create({
    //     header: { fontSize: 18, marginBottom: 20, textAlign: 'center' },
    //     page: {
    //         padding: 30,
    //     },
    //     table: {
    //         width: "auto",
    //         borderStyle: "solid",
    //         borderWidth: 1,
    //         borderColor: "#bfbfbf",
    //         borderRightWidth: 0,
    //         borderBottomWidth: 0,
    //     },
    //     tableRow: {
    //         flexDirection: "row",
    //     },
    //     tableColHeader: {
    //         flex: 1,
    //         borderStyle: "solid",
    //         borderWidth: 1,
    //         borderColor: "#bfbfbf",
    //         borderTopWidth: 0,
    //         borderLeftWidth: 0,
    //         backgroundColor: "#f3f3f3",
    //     },
    //     tableCol: {
    //         // flex: 1,
    //         // borderStyle: "solid",
    //         // borderWidth: 1,
    //         // borderColor: "#bfbfbf",
    //         // borderTopWidth: 0,
    //         // borderLeftWidth: 0,
    //         width: "25%",
    //         // borderStyle: "solid",
    //         // borderWidth: 1,
    //         borderLeftWidth: 0,
    //         borderTopWidth: 0
    //     },
    //     tableCellHeader: {
    //         margin: 5,
    //         fontSize: 10,
    //         fontWeight: "bold",
    //     },
    //     tableCell: {
    //         margin: 5,
    //         fontSize: 10,
    //     },

    //     footer_right: {
    //         position: 'absolute',
    //         bottom: 35,
    //         left: 40,
    //         right: 40,
    //         textAlign: 'right',
    //         fontSize: 10,
    //     },
    //     footer_left: {
    //         position: 'absolute',
    //         bottom: 35,
    //         left: 40,
    //         right: 40,
    //         textAlign: 'left',
    //         fontSize: 10,
    //     },
    //     font: {
    //         fontFamily: "Montserrat"
    //     },
    //     tableCell_2: {
    //         // margin: "auto",
    //         // marginTop: 5,
    //         fontSize: 10,
    //         // textAlign: "left",
    //         color: "#353535",
    //         textAlign: 'justify',
    //     },
    // });

    const styles = StyleSheet.create({
        page: { padding: 30, fontSize: 12 },
        titulos: {
            fontWeight: "bold",
            fontSize: "14px"
        },
        subTitulos: {
            fontWeight: "bold",
            fontSize: "12px",

        },
        contenedor: {
            border: "1px solid black",
            marginRight: "15px",
            marginLeft: "15px",
        },
        table: {
            // display: "table",
            width: "auto",
            // borderStyle: "solid",
            // borderWidth: 1,
            // borderColor:"#000",
            // backgroundColor: "#E2E2E2",
            borderRightWidth: 0,
            borderBottomWidth: 0,
            border: "none"
        },
        tableRow: {
            // margin: "auto",
            flexDirection: "row",
            // borderStyle: "solid",
            // borderBottom: "1px"
        },
        tableCol: {
            width: "25%",
            // borderStyle: "solid",
            // borderWidth: 1,
            margin: 3,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },

        tableCell_2: {
            // margin: "auto",
            // marginTop: 5,
            fontSize: 10,
            // textAlign: "left",
            color: "#353535",
            textAlign: 'justify',
        },
        tableCell: {
            // margin: "auto",
            // marginTop: 5,
            fontSize: 10,
            // textAlign: "left",
            textAlign: 'justify',
        },

        footer_right: {
            position: 'absolute',
            bottom: 35,
            left: 40,
            right: 40,
            textAlign: 'right',
            fontSize: 10,
        },
        footer_left: {
            position: 'absolute',
            bottom: 35,
            left: 40,
            right: 40,
            textAlign: 'left',
            fontSize: 10,
        },
        footer_firma: {
            position: 'absolute',
            bottom: 75,
            left: 40,
            right: 40,
            textAlign: 'left',
            fontSize: 10,
        },
        absolutePosition: {
            position: 'absolute',
            bottom: 75,
            left: 15,
            right: 15,
            textAlign: 'left',
            fontSize: 10,
        }
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>


                <View>
                    <Row
                        style={{
                            color: colors.white01,
                            marginRight: "15px",
                            marginLeft: "15px",
                        }}
                    >
                        <Col>

                            <Image
                                src={logo}
                                //alt="No se pudo cargar la imagen"
                                style={{
                                    height: "40",
                                    width: "75px",
                                    marginTop: "0px"
                                }}
                            />

                        </Col>
                        <Col
                            style={{
                                textAlign: "center",
                                marginTop: "-40px"
                            }}
                        >
                            <Text style={{ color: colors.black, fontWeight: "bold", fontSize: 16 }} >Operadora Tzedaká, S.A.P.I. DE C.V</Text>
                            <Text style={{ color: colors.black, fontWeight: "bold", marginTop: "5px", fontSize: 14 }} >Solicitud de Crédito</Text>
                        </Col>
                    </Row>
                </View>

                <View style={{ marginTop: 10 }} >
                    <Text style={{ fontSize: 14 }} >Datos Generales</Text>
                </View>

                <Row
                    style={{
                        // marginRight: "15px",
                        // marginLeft: "15px",
                        borderRadius: "8px"
                    }}
                >
                    <div style={{ border: "1px solid black" }} >
                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Nombre Completo:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Numero Teléfono:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Correo Electrónico:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>País Nacimiento:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.nombres + " " + cliente.paterno + " " + cliente.materno}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.telefono}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.correo}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.lugarNacimiento.pais.pais}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>
                        <View><div style={{ borderBottom: "1px solid #353535", marginRight: 5, marginLeft: 5 }} >---</div></View>
                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>

                                        <Text style={styles.tableCell}>Estado de Nacimiento:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Ciudad de Nacimiento:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Nivel de Estudio:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Estado Civil:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.lugarVivienda.estado.estado}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.lugarVivienda.ciudad.ciudad}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.nivelEstudio.nivelEstudio}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.estadoCivil.estadoCivil}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>
                        <View><div style={{ borderBottom: "1px solid #353535", marginRight: 5, marginLeft: 5 }} >---</div></View>
                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>

                                        <Text style={styles.tableCell}>Clave Lector:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>INE Folio:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>INE Numero:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>CURP:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.ine.claveElector}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.ine.ineFolio}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.ine.ineNumero}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.ine.curp}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>
                        <View><div style={{ borderBottom: "1px solid #353535", marginRight: 5, marginLeft: 5 }} >---</div></View>
                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>

                                        <Text style={styles.tableCell}>RFC:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>País Donde Vives:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Estado Donde Vives:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Ciudad Donde Vives:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.ine.rfc}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.lugarVivienda.pais.pais}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.lugarVivienda.estado.estado}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.lugarVivienda.ciudad.ciudad}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>
                        <View><div style={{ borderBottom: "1px solid #353535", marginRight: 5, marginLeft: 5 }} >---</div></View>
                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>

                                        <Text style={styles.tableCell}>Localidad donde Vives:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Calle y Numero de Casa:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Tipo de Vivienda:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Nombre del Cónyuge:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.lugarVivienda.localidad.localidad}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.lugarVivienda.calleNumeroCasa}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.lugarVivienda.tipoVivienda.tipoVivienda}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.nombreConyuge}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>
                        <View><div style={{ borderBottom: "1px solid #353535", marginRight: 5, marginLeft: 5 }} >---</div></View>
                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>

                                        <Text style={styles.tableCell}>Gastos de Económicos:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Gastos de Renta:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Gastos de Salud:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.dependientesEconomicos}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.gastosPropios.renta)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.gastosPropios.educacion)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.gastosPropios.salud)}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>
                        <View><div style={{ borderBottom: "1px solid #353535", marginRight: 5, marginLeft: 5 }} >---</div></View>
                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>

                                        <Text style={styles.tableCell}>Dependientes Agua:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Gastos de Luz:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Gastos de Internet:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Otros Gastos:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.gastosPropios.agua)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.gastosPropios.luz)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.gastosPropios.internet)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.gastosPropios.otros)}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>
                        <View><div style={{ borderBottom: "1px solid #353535", marginRight: 5, marginLeft: 5 }} >---</div></View>
                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>

                                        <Text style={styles.tableCell}>Fecha de Nacimiento:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>*Edad Estimada*:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{Fecha.formatDate(cliente.fechaNacimiento)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{ClienteModel.calcularEdad(cliente.fechaNacimiento)} años</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>
                    </div>
                </Row>

                <View style={{ marginTop: 20 }} >
                    <Text style={{ fontSize: 14 }} >
                        {
                            cliente.trabajoNegocio.esTrabajador === 1 ?
                                "Datos del Empleo"
                                :
                                cliente.trabajoNegocio.tieneNegocio === 1 ?
                                    "Datos del Negocio"
                                    :
                                    cliente.trabajoNegocio.tieneNegocio === 0 && cliente.trabajoNegocio.esTrabajador === 0 ?
                                        "La persona no especifico si es trabajador o cliente"
                                        :
                                        "Algo No Esta Bien..."
                        }
                    </Text>
                </View>

                <Row
                    style={{
                        //marginRight: "15px",
                        //marginLeft: "15px",
                        borderRadius: "8px"
                    }}
                >
                    <div style={{ border: "1px solid black" }} >
                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    //marginLeft: "5px",
                                    //marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Puesto/Categoría:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Teléfono:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Dirección:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Punto de Referencia:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.trabajoNegocio.puestoCategoria}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.trabajoNegocio.telefono}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.trabajoNegocio.direccion}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.trabajoNegocio.puntoReferencia}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>

                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{cliente.trabajoNegocio.tieneNegocio === 1 ? "Tiempo con tu Negocio" : "Antigüedad en tu Trabajo"}:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Ingreso Mensual:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Dias Que Labora:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Horario De Trabajo:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.trabajoNegocio.antiguedad}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.trabajoNegocio.ingresoMensual)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>
                                            {cliente.trabajoNegocio.diasLaborales.lunes === undefined ? "" : cliente.trabajoNegocio.diasLaborales.lunes === 1 ? "Lunes" : ""}
                                            {cliente.trabajoNegocio.diasLaborales.martes === undefined ? "" : cliente.trabajoNegocio.diasLaborales.martes === 1 ? ", Martes" : ""}
                                            {cliente.trabajoNegocio.diasLaborales.miercoles === undefined ? "" : cliente.trabajoNegocio.diasLaborales.miercoles === 1 ? ", Miercoles" : ""}
                                            {cliente.trabajoNegocio.diasLaborales.jueves === undefined ? "" : cliente.trabajoNegocio.diasLaborales.jueves === 1 ? ", Jueves" : ""}
                                            {cliente.trabajoNegocio.diasLaborales.viernes === undefined ? "" : cliente.trabajoNegocio.diasLaborales.viernes === 1 ? ", Viernes" : ""}
                                            {cliente.trabajoNegocio.diasLaborales.sabado === undefined ? "" : cliente.trabajoNegocio.diasLaborales.sabado === 1 ? ", Sabado" : ""}
                                            {cliente.trabajoNegocio.diasLaborales.domingo === undefined ? "" : cliente.trabajoNegocio.diasLaborales.domingo === 1 ? ", Domingo" : ""}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>
                                            De {Tiempo.convertTimeTo12HourFormat(cliente.trabajoNegocio.horario.entrada)} a {Tiempo.convertTimeTo12HourFormat(cliente.trabajoNegocio.horario.salida)}
                                        </Text>
                                    </View>
                                </View>
                            </Row>
                        </View>
                    </div>

                </Row>
                <View style={styles.footer_left}>
                    <Text>Documento generado por: {nombreUsuario}</Text>
                </View>
                <Text style={styles.footer_right} render={({ pageNumber, totalPages }) => (
                    `Página ${pageNumber} de ${totalPages} `
                )} fixed />

            </Page>

            <Page size="A4" style={styles.page}>
                {
                    cliente.trabajoNegocio.tieneNegocio === 1 ?
                        <>
                            <Row
                                style={{
                                    //marginRight: "15px",
                                    //marginLeft: "15px",
                                }}
                            >
                                <Col>
                                    <Text style={styles.titulos} >
                                        Análisis del Negocio
                                    </Text>
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    //marginRight: "15px",
                                    //marginLeft: "15px",
                                    borderRadius: "8px"
                                }}
                            >
                                <div style={{ border: "1px solid black" }} >
                                    <View style={styles.table}>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Ventas Semanales:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Inversion Semanal:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Tipo Local:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Renta de tu Negocio:</Text>
                                                </View>
                                            </View>
                                        </Row>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.trabajoNegocio.ventasSemanales)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.trabajoNegocio.inversionSemanal/* cliente.inversionSemanalNegocioCliente*/)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{cliente.trabajoNegocio.TipoLocal.tipoLocal/* cliente.descripcionLocalPropioRentado*/}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.trabajoNegocio.gastos.renta /*cliente.gastosRentaNegocioCliente*/)}</Text>
                                                </View>
                                            </View>
                                        </Row>
                                    </View>

                                    <View style={styles.table}>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Gastos de Luz:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Gastos de Internet:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Otros Gastos:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Cuanto Tiene Mercancía:</Text>
                                                </View>
                                            </View>
                                        </Row>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.trabajoNegocio.gastos.luz)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.trabajoNegocio.gastos.internet)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.trabajoNegocio.gastos.otros)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.trabajoNegocio.cuantoTieneMercancia)}</Text>
                                                </View>
                                            </View>
                                        </Row>
                                    </View>

                                    <View style={styles.table}>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Dinero Que Le Deben:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Dinero Ahorrado:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Ganancia Negocio:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Tiene Otro Ingresos:</Text>
                                                </View>
                                            </View>
                                        </Row>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.trabajoNegocio.cuentoDineroLeDeben)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.trabajoNegocio.dineroEfectivoAhorrado)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{cliente.trabajoNegocio.cuantoCalculaTieneGananciaEnSuNegocio}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{cliente.trabajoNegocio.cuantoTieneMercancia/* cliente.cuantoTieneMercanciaCliente == 1 ? "Si" : "No"*/}</Text>
                                                </View>
                                            </View>
                                        </Row>
                                    </View>

                                    <View style={styles.table}>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Descripción Otros Ingresos:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Declaración Patrimonial Vivienda:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Declaración Patrimonial Vehículos:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Declaración Patrimonial Equipo:</Text>
                                                </View>
                                            </View>
                                        </Row>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{cliente.trabajoNegocio.otroIngresoDescripcion/* cliente.descripcionOtrosIngresosClientes*/}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.trabajoNegocio.declaracionPatrimonial.vivienda/* cliente.viviendaDeclaracionPatrimonialCliente*/)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.trabajoNegocio.declaracionPatrimonial.vehiculos /*cliente.vehiculosDeclaracionPatrimonialCliente*/)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{Moneda.formatCurrency(cliente.trabajoNegocio.declaracionPatrimonial.equipo/* cliente.mobiliarioEquipoDeclaracionPatrimonial*/)}</Text>
                                                </View>
                                            </View>
                                        </Row>
                                    </View>

                                    <View style={styles.table}>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Ha Tenido Créditos:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Institución Donde le Dieron el Crédito:</Text>
                                                </View>
                                            </View>
                                        </Row>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{cliente.trabajoNegocio.haTenidoCreditos === 1 ? "Si" : "No"/* cliente.haTenidoCreditoBancariosCliente == 1 ? "Si" : "No"*/}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{cliente.trabajoNegocio.institucionDondeLeDieronElCredito/*NumeroTools.castFormatoMoneda(cliente.institucionDieronCreditoCliente)*/}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                </View>
                                            </View>
                                        </Row>
                                    </View>
                                </div>

                            </Row>
                        </>
                        :
                        null
                }

                <div style={{ height: "15px" }} ></div>
                <Row
                    style={{
                        //marginRight: "15px",
                        //marginLeft: "15px",
                    }}
                >
                    <Col>
                        <Text style={styles.titulos} >
                            Referencias De Personas Que No Vivan Con Usted (2 Personas)
                        </Text>
                    </Col>

                </Row>
                <Row
                    style={{
                        //marginRight: "15px",
                        //marginLeft: "15px",
                        borderRadius: "8px"
                    }}
                >
                    <div style={{ border: "1px solid black" }} >

                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Nombre Completo y Apellido:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Parentesco:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Dirección:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Teléfono:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.refenciasPersonas.personaUno.nombreCompleto}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.refenciasPersonas.personaUno.parentesco}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.refenciasPersonas.personaUno.direccion}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.refenciasPersonas.personaUno.telefono}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>

                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Nombre Completo y Apellido:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Parentesco:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Dirección:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Teléfono:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.refenciasPersonas.personaDos.nombreCompleto}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.refenciasPersonas.personaDos.parentesco}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.refenciasPersonas.personaDos.direccion}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.refenciasPersonas.personaDos.telefono}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>
                    </div>
                </Row>
                <div style={{ height: "15px" }} ></div>
                <Row
                    style={{
                        //marginRight: "15px",
                        //marginLeft: "15px",
                    }}
                >
                    <Col>
                        <Text style={styles.titulos} >
                            Datos del Crédito que Solicita
                        </Text>
                    </Col>

                </Row>
                <Row
                    style={{
                        //marginRight: "15px",
                        //marginLeft: "15px",
                        borderRadius: "8px"
                    }}
                >
                    <div style={{ border: "1px solid black" }} >

                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Tipo de Crédito:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Monto Solicitado:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Plazo del Crédito:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Forma de Pago:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{solicitudCredito.tipoCredito.tipoCredito}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{Moneda.formatCurrency(solicitudCredito.montoSolicitado.montoSolicitado)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{solicitudCredito.plazoCredito.plazoCredito} {solicitudCredito.plazoCredito.descripcion}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{solicitudCredito.formaPago.formaPago}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>
                        {
                            solicitudCredito.status.IdStatus === 2 ?
                                <View style={styles.table}>
                                    <Row
                                        style={{
                                            marginRight: "5px",
                                            marginLeft: "5px",
                                            marginTop: "5px"
                                        }}
                                    >
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>Monto Aprobado:</Text>
                                            </View>
                                        </View>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "5px",
                                            marginLeft: "5px",
                                            marginTop: "5px"
                                        }}
                                    >
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell_2}>{Moneda.formatCurrency(solicitudCredito.montoAprobado)}</Text>
                                            </View>
                                        </View>
                                    </Row>
                                </View>
                                :
                                null
                        }

                    </div>
                </Row>

                <View style={styles.absolutePosition}>
                    <Text
                        style={{
                            textAlign: "justify",
                            fontSize: "10px",
                            marginRight: "15px",
                            marginLeft: "15px"
                        }}
                    >
                        Por este medio autorizo expresamente a Operadora Tzedaká S.A.P.I de C.V. para que por conducto de sus colaboradores lleve a cabo investigaciones
                        sobre mi comportamiento e historial crediticio, así como cualquier otra información de naturaleza análoga en las sociedades de información Crediticia
                        que estime conveniente; así mismo declaro que conozco la naturaleza y alcance de la información, así como el uso que Operadora Tzedaká S.A.P.I de C.V.
                        hará de la información y del hecho que Operadora Tzedaká S.A.P.I de C.V. podrá realizar consultas periódicas de mi historial crediticio durante el tiempo
                        que mantenga relación jurídica. Estoy consciente y autorizo que este documento quede en propiedad de Operadora Tzedaká S.A.P.I de C.V. para efectos de
                        control y cumplimiento de la ley para regular a las sociedades de Información Crediticia, asi mismo declaro bajo protesta de decir la verdad que la información
                        compartida en esta encuesta es verídica y comprobable.
                    </Text>
                    <Text
                        style={{
                            textAlign: "center",
                            fontSize: "10px",
                            marginTop: "20px"
                        }}
                    >
                        _____________________________________
                    </Text>
                    <Text
                        style={{
                            textAlign: "center",
                            fontSize: "10px",
                            marginTop: "10px"
                        }}
                    >
                        {cliente.nombres} {cliente.paterno} {cliente.materno}
                    </Text>
                </View>

                <View style={styles.footer_left}>
                    <Text>Documento generado por: {nombreUsuario}</Text>
                </View>
                <Text style={styles.footer_right} render={({ pageNumber, totalPages }) => (
                    `Página ${pageNumber} de ${totalPages} `
                )} fixed />
            </Page>
            {/* 
            <Page size="A4" style={styles.page}>
               

            </Page> */}



        </Document>
    );
};

export default SolicitudCreditoPdf;
