const formatDate = (dateString: string): string => {    
    const array: string[] = dateString.substring(0, 10).split("-")
    const year: string = array[0];
    const month: string = array[1];
    const day: string = array[2];

    return `${day}/${month}/${year}`

};

const formatDateInput = (dateString: string): string => {    
    const date = new Date(dateString);
    const day = date.getDate() + 1;
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${year}-${formattedMonth}-${formattedDay}`;

};

const getDateToDay = (): string => {
    const today: Date = new Date();
    const day: number = today.getDate();
    const month: number = today.getMonth() + 1;
    const year: number = today.getFullYear();
    const formattedDay: string = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth: string = month < 10 ? `0${month}` : `${month}`;
    const label = `${year}-${formattedMonth}-${formattedDay}`
    return label;
}

const getDateToDayWithFormat = (): string => {
    const today: Date = new Date();
    const day: number = today.getDate();
    const month: number = today.getMonth() + 1;
    const year: number = today.getFullYear();
    const formattedDay: string = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth: string = month < 10 ? `0${month}` : `${month}`;
    const label = `${year}-${formattedMonth}-${formattedDay}`
    return formatDate(label);
}

const Fecha = {
    formatDate,
    getDateToDay,
    getDateToDayWithFormat,
    formatDateInput
}

export default Fecha;