

import Pais from "../interfaces/Pais.interface";

const getEstructura = (): Pais => {
    const pais: Pais = {
        IdPais: 0,
        pais: ""
    }
    return pais;
};

const PaisModel = {
    getEstructura,
}

export default PaisModel;