import Cliente from "../interfaces/Cliente.interface";
import CiudadModel from "./Ciudad.model";
import EstadoModel from "./Estado.model";
import EstadoCivilModel from "./EstadoCivil.model";
import LocalidadModel from "./Localidad.model";
import NivelEstudioModel from "./NivelEstudio.model";
import PaisModel from "./Pais.model";
import ReligionModel from "./Religion.model";
import TipoViviendaModel from "./TipoVivienda.model";

const getEstructura = (): Cliente => {
    const cliente: Cliente = {
        IdCliente: "",
        imgFotoPersona: "",
        nombres: "",
        paterno: "",
        materno: "",
        fechaNacimiento: "1990-01-01",
        telefono: "",
        correo: "",
        lugarNacimiento: {
            pais: PaisModel.getEstructura(),
            estado: EstadoModel.getEstructura(),
            ciudad: CiudadModel.getEstructura(),
            localidad: LocalidadModel.getEstructura(),
            calleNumeroCasa: "",
            tipoVivienda: TipoViviendaModel.getEstructura(),
            cp: ""
        },
        nivelEstudio: NivelEstudioModel.getEstructura(),
        estadoCivil: EstadoCivilModel.getEstructura(),
        ine: {
            claveElector: "",
            ineFolio: "",
            ineNumero: "",
            curp: "",
            rfc: "",
        },
        lugarVivienda: {
            pais: PaisModel.getEstructura(),
            estado: EstadoModel.getEstructura(),
            ciudad: CiudadModel.getEstructura(),
            localidad: LocalidadModel.getEstructura(),
            calleNumeroCasa: "",
            tipoVivienda: TipoViviendaModel.getEstructura(),
            cp: ""
        },
        nombreConyuge: "",
        dependientesEconomicos: "",
        gastosPropios: {
            renta: "0.00",
            educacion: "0.00",
            salud: "0.00",
            agua: "0.00",
            luz: "0.00",
            internet: "0.00",
            otros: "0.00",
        },
        religion: ReligionModel.getEstructura(),
        descripcionOtraReligion: "",
        trabajoNegocio: {
            esTrabajador: 0,
            tieneNegocio: 0,
            puestoCategoria: "",
            telefono: "",
            direccion: "",
            puntoReferencia: "",
            antiguedad: "",
            ingresoMensual: "0.00",
            diasLaborales: {
                lunes: 0,
                martes: 0,
                miercoles: 0,
                jueves: 0,
                viernes: 0,
                sabado: 0,
                domingo: 0,
            },
            horario: {
                entrada: "00:00:00",
                salida: "00:00:00",
            },
            ventasSemanales: "0.00",
            inversionSemanal: "0.00",
            TipoLocal: {
                IdTipoLocal: 0,
                tipoLocal: ""
            },
            gastos: {
                renta: "0.00",
                educacion: "0.00",
                salud: "0.00",
                agua: "0.00",
                luz: "0.00",
                internet: "0.00",
                otros: "0.00",
            },
            cuantoTieneMercancia: "0.00",
            cuentoDineroLeDeben: "0.00",
            dineroEfectivoAhorrado: "0.00",
            cuantoCalculaTieneGananciaEnSuNegocio: "0.00",
            tieneOtroingreso: 0,
            otroIngresoDescripcion: "",
            declaracionPatrimonial: {
                vivienda: "0.00",
                vehiculos: "0.00",
                equipo: "0.00",
                otros: "0.00"
            },
            haTenidoCreditos: 0,
            institucionDondeLeDieronElCredito: ""
        },
        refenciasPersonas: {
            personaUno: {
                nombreCompleto: "",
                parentesco: "",
                direccion: "",
                telefono: ""
            },
            personaDos: {
                nombreCompleto: "",
                parentesco: "",
                direccion: "",
                telefono: ""
            }
        },
        mgFotoPersona: "",
        imgFachadaCasa: "",
        imgINE: "",
        imgINE2: "",
        solicitudCredito: []
    }
    return cliente;
};

const calcularEdad = (fechaNacimiento: string): number => {
    const hoy = new Date();
    const nacimiento = new Date(fechaNacimiento);

    let edad = hoy.getFullYear() - nacimiento.getFullYear();
    const mes = hoy.getMonth() - nacimiento.getMonth();

    if (mes < 0 || (mes === 0 && hoy.getDate() < nacimiento.getDate())) {
        edad--;
    }

    return edad;
}

const validarDatosCliente = (cliente: Cliente): { esValido: boolean, erroresMensaje: string[] } => {
    const validarString = (valor: string) => valor.trim() !== '';
    const validarNumero = (valor: number | string) => Number(valor) !== 0;

    let esValido = true;
    let erroresMensaje: string[] = [];

    // Validar campos principales
    if (!validarString(cliente.nombres)) {
        esValido = false;
        erroresMensaje.push("El campo 'Nombre del Cliente' no puede estar vacío.");
    }
    if (!validarString(cliente.paterno)) {
        esValido = false;
        erroresMensaje.push("El campo 'Apellido Paterno' no puede estar vacío.");
    }
    if (!validarString(cliente.materno)) {
        esValido = false;
        erroresMensaje.push("El campo 'Apellido Materno' no puede estar vacío.");
    }
    if (!validarString(cliente.fechaNacimiento)) {
        esValido = false;
        erroresMensaje.push("El campo 'Fecha de Nacimiento' no puede estar vacío.");
    }
    if (!validarString(cliente.telefono)) {
        esValido = false;
        erroresMensaje.push("El campo 'Numero Teléfono (Datos Generales)' no puede estar vacío.");
    }
    if (!validarString(cliente.correo)) {
        esValido = false;
        erroresMensaje.push("El campo 'Correo Electrónico' no puede estar vacío.");
    }

    // Validar INE
    if (!validarString(cliente.ine.claveElector)) {
        esValido = false;
        erroresMensaje.push("El campo 'Clave de Elector' no puede estar vacío.");
    }
    if (!validarString(cliente.ine.curp)) {
        esValido = false;
        erroresMensaje.push("El campo 'CURP' no puede estar vacío.");
    }
    if (!validarString(cliente.ine.rfc)) {
        esValido = false;
        erroresMensaje.push("El campo 'RFC' no puede estar vacío.");
    }

    // Validar estado civil
    if (!validarNumero(cliente.estadoCivil.IdEstadoCivil)) {
        esValido = false;
        erroresMensaje.push("El campo 'Estado Civil' no puede ser 0.");
    }

    // Validar nivel de estudio
    if (!validarNumero(cliente.nivelEstudio.IdNivelEstudio)) {
        esValido = false;
        erroresMensaje.push("El campo 'Nivel de Estudio' no ha sido especificado");
    }

    // Validar religión
    if (!validarNumero(cliente.religion.IdReligion)) {
        esValido = false;
        erroresMensaje.push("El campo 'Religion que Profesa' no puede ser 0.");
    }

    // Validar trabajo/negocio
    if (!validarNumero(cliente.trabajoNegocio.esTrabajador) && !validarNumero(cliente.trabajoNegocio.tieneNegocio)) {
        esValido = false;
        erroresMensaje.push("El campo 'Tiene Empleo / Tiene Negocio' no ha sido especificado.");
    }
    if (!validarNumero(cliente.trabajoNegocio.ingresoMensual)) {
        esValido = false;
        erroresMensaje.push("El campo 'Ingreso Mensual' no puede ser 0.");
    }

    // Validar gastos propios
    // if (!validarNumero(cliente.gastosPropios.renta)) {
    //     esValido = false;
    //     erroresMensaje.push("El campo 'Gastos de Renta' no puede ser 0.");
    // }
    // if (!validarNumero(cliente.gastosPropios.educacion)) {
    //     esValido = false;
    //     erroresMensaje.push("El campo 'Gastos de Educación' no puede ser 0.");
    // }
    // if (!validarNumero(cliente.gastosPropios.internet)) {
    //     esValido = false;
    //     erroresMensaje.push("El campo 'Gastos de Internet' no puede ser 0.");
    // }
    // if (!validarNumero(cliente.gastosPropios.otros)) {
    //     esValido = false;
    //     erroresMensaje.push("El campo 'Otros Gastos' no puede ser 0.");
    // }

    // Validar trabajo/negocio - gastos
    // if (!validarNumero(cliente.trabajoNegocio.gastos.renta)) {
    //     esValido = false;
    //     erroresMensaje.push("El campo 'trabajoNegocio.gastos.renta' no puede ser 0.");
    // }
    // if (!validarNumero(cliente.trabajoNegocio.gastos.educacion)) {
    //     esValido = false;
    //     erroresMensaje.push("El campo 'trabajoNegocio.gastos.educacion' no puede ser 0.");
    // }
    // if (!validarNumero(cliente.trabajoNegocio.gastos.internet)) {
    //     esValido = false;
    //     erroresMensaje.push("El campo 'trabajoNegocio.gastos.internet' no puede ser 0.");
    // }
    // if (!validarNumero(cliente.trabajoNegocio.gastos.otros)) {
    //     esValido = false;
    //     erroresMensaje.push("El campo 'trabajoNegocio.gastos.otros' no puede ser 0.");
    // }

    return { esValido, erroresMensaje };
};



const ClienteModel = {
    getEstructura,
    validarDatosCliente,    
    calcularEdad,
};

export default ClienteModel;

