import Estado from "../interfaces/Estado.interface";
import PaisModel from "./Pais.model";

const getEstructura = (): Estado => {
    const estado: Estado = {
        IdEstado: 0,
        pais: PaisModel.getEstructura(),
        calve: "",
        estado: ""
    }
    return estado;
}

const EstadoModel = {
    getEstructura,
}

export default EstadoModel;
