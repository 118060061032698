import ApiConfig from "../config/Api.config";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";
import WhatsAppMensaje from "../interfaces/WhatsAppMensaje.interface";

// const urlServicio = `http://127.0.0.1:10001/api-whatsapp/services`
const urlServicio = `https://whatsappservicelocal.serveo.net/api-whatsapp/services`


const url = `${ApiConfig.URLBase}/whatsApp`

const mandarMensaje = async (telefono: string, value: string): Promise<Response> => {
    console.log({url: `${urlServicio}/mandar/mensaje`,});
    const request: Request = {
        url: `${urlServicio}/mandar/mensaje`,
        metodo: "POST",
        data: { telefono, value }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const getStatusWhatsApp = async (): Promise<Response> => {
    const request: Request = {
        url: `${urlServicio}/status`,
        metodo: "GET",
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const logoutWhatsApp = async (): Promise<Response> => {
    const request: Request = {
        url: `${urlServicio}/logout`,
        metodo: "GET",
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const sendMessage = async (telefono: string, texto: string): Promise<Response> => {
    console.log({url: `${urlServicio}/sendMessage`,});
    const request: Request = {
        url: `${urlServicio}/sendMessage`,
        metodo: "POST",
        data: { telefono, texto }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};


const listarMensajes = async (IdWhatsAppMensaje: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar/${IdWhatsAppMensaje}`,
        metodo: "GET",
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const guardarMensajes = async (mensajesWhatsApp: WhatsAppMensaje): Promise<Response> => {
    const request: Request = {
        url: `${url}/guardar`,
        metodo: "POST",
        data: { mensajesWhatsApp }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const eliminarMensajes = async (IdWhatsAppMensaje: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/eliminar/${IdWhatsAppMensaje}`,
        metodo: "DELETE",
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};


const WhatsAppService = {
    mandarMensaje,
    getStatusWhatsApp,
    logoutWhatsApp,
    sendMessage,
    listarMensajes,
    guardarMensajes,
    eliminarMensajes,
};

export default WhatsAppService;