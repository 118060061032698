import Usuario from "../interfaces/Usuario.interface";
import md5 from 'md5';
import Cookies from 'universal-cookie';
import UsuarioService from "../services/Usuario.service";
import Swal from "sweetalert2";
import colors from "../config/Colors.config";
import Response from "../interfaces/Response.interface";

const cookies = new Cookies();

const getEstructura = (): Usuario => {
    const usuario: Usuario = {
        IdUsuario: 0,
        nombreCompleto: "",
        username: "",
        password: "",
        correo: "",
        status: { IdStatus: 1, descripcion: "" },
        permisos: { menuClientes: 0, administraciondeCorreos: 0, administraciondeWhatsApp: 0, administraciondeUsuarios: 0, }
    }
    return usuario;
}

const hashearPassword = (password: string): string => {
    let hash = `${md5(password)}${md5(md5(password))}`
    return hash;
}

const cerrarSesion = (): void => {
    Swal.fire({
        icon: "info",
        title: "Salir del Sistema",
        text: "¿Estas seguro de salir del sistema?",
        showConfirmButton: true,
        confirmButtonColor: colors.blue,
        denyButtonColor: colors.blue,
        cancelButtonText: "No",
        confirmButtonText: "Si",
        reverseButtons: true,
        showCancelButton: true,
        timer: 10000,
        timerProgressBar: true,
    }).then((response) => {
        if (response.isConfirmed) {
            cookies.remove("usuario", { path: '/' });
            window.location.href = "/";
        }
    })

}

const validarUsuarioLocal = (): boolean => {
    const usuario = cookies.get("usuario");
    return usuario === undefined ? true : false;
}

const getIdUsuario = (): number => {
    const usuario = cookies.get("usuario");
    let IdUsuario: number

    if (usuario === undefined) { IdUsuario = 0 } else { IdUsuario = usuario.IdUsuario };
    return IdUsuario;
}

const getUsername = (): string => {
    const usuario = cookies.get("usuario");
    if (usuario === undefined) {
        return "*";
    } else {
        const username: string = usuario.username;
        return username;
    }

}

const getUsuario = async (): Promise<Usuario> => {
    const IdUsuario: number = getIdUsuario();
    const response: Response = await UsuarioService.getUsuario(IdUsuario);
    const usuario: Usuario = response.data.value
    return usuario;
}

const validarCorreo = (correo: string): boolean => {
    // let value: boolean = true;

    // const array: string[] = correo.split("@");
    

    // return value
    const domainRegex = /^[^\s@]+@tzedaka\.com\.mx$/; // Validar exactamente el dominio
    return domainRegex.test(correo);
}

const UsuarioModel = {
    getEstructura,
    hashearPassword,
    cerrarSesion,
    validarUsuarioLocal,
    getIdUsuario,
    getUsername,
    getUsuario,
    validarCorreo,
}

export default UsuarioModel;