import { FC, useEffect, useState } from "react";
import colors from "../../config/Colors.config";
import { Col, Label, Row } from "reactstrap";
import Block from "../../components/Block.component";
import logo2 from "../../assets/img/svgChar2.svg";
import Device from "../../interfaces/Device.interface";
import DeviceConfig from "../../config/Device.config";
import UsuarioModel from "../../models/Usuario.model";
import Usuario from "../../interfaces/Usuario.interface";

const Home: FC = () => {

    const [usuario, setUsuario] = useState(UsuarioModel.getEstructura());
    const [data] = useState<Device>(DeviceConfig.data());

    const cargarDatos = async (): Promise<void> => {
        const data: Usuario = await UsuarioModel.getUsuario();
        setUsuario(data);
    }

    useEffect(() => {
        cargarDatos();
    }, []);

    return (
        <section style={{ minHeight: "100vh", height: "100vh", maxHeight: "auto", marginLeft: 0, backgroundColor: colors.white01 }} >
            <Block height={"10vh"} />
            <Row style={{ marginRight: 0, marginLeft: 0 }} >
                <Col style={{ textAlign: "center" }} >
                    <img src={logo2} height={data.isDesktop ? 300 : 150} style={{ marginLeft: data.isDesktop ? 155 : 0 }} alt="No se pudo cargar la imagen" />
                </Col>
            </Row>
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                <Col style={{ textAlign: "center" }} >
                    <Label style={{ fontSize: data.isDesktop ? 52 : 42, fontWeight: "bold", color: colors.blue }} >
                        TZEDAKÁ
                    </Label>
                </Col>
            </Row>
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                <Col style={{ textAlign: "center" }} >
                    <Label style={{ fontSize: data.isDesktop ? 36 : 28, fontWeight: "bold", color: colors.blue }} >
                        CRÉDITOS AL ALCANCE DE TODOS
                    </Label>
                </Col>
            </Row>
            <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                <Col style={{ textAlign: "center" }} >
                    <Label style={{ fontSize: data.isDesktop ? 26 : 18, fontWeight: "bold", color: colors.blue }} >
                        Bienvenido {usuario.nombreCompleto}
                    </Label>
                </Col>
            </Row>
        </section>
    )
}

export default Home