import Localidad from "../interfaces/Localidad.interface";
import CiudadModel from "./Ciudad.model";
import EstadoModel from "./Estado.model";
import PaisModel from "./Pais.model";



const getEstructura = (): Localidad => {
    const localidad: Localidad = {
        IdLocalidad: 0,
        pais: PaisModel.getEstructura(),
        estado: EstadoModel.getEstructura(),
        ciudad: CiudadModel.getEstructura(),
        localidad: "",
    }
    return localidad
};

const LocalidadModel = {
    getEstructura
}

export default LocalidadModel



