import { ChangeEvent, FocusEvent, KeyboardEvent, FC, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Block from "../components/Block.component";
import { Card, Col, DropdownItem, FormGroup, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from "reactstrap";
import imgTzedakaLogo from "../assets/img/logoTzedaka.png";
//import imgTzedakaLogo2 from "../assets/img/222logoTzedaka.png";
import colors from "../config/Colors.config";
import Device from "../interfaces/Device.interface";
import DeviceConfig from "../config/Device.config";
import Response from "../interfaces/Response.interface";
import WhatsAppService from "../services/WhatsApp.service";
import WhatsAppMensaje from "../interfaces/WhatsAppMensaje.interface";
import WhatsAppModel from "../models/WhatsApp.model";
import { IconButton } from "@material-ui/core";
import { Add, Cached, Chat, Close, Edit } from "@material-ui/icons";
import Styles from "../assets/css/Styles.css";
import "./whatsApp.css"
import Components from "../tools/Components.tool";
import Swal from "sweetalert2";
import WhatsAppMessage from "../components/WhatsAppMessage.componet";
import Tiempo from "../tools/Tiempo.tool";

const WhatsAppConfig: FC = () => {

    const [data] = useState<Device>(DeviceConfig.data());

    const [mensajesWhatsAppLista, setMensajesWhatsAppLista] = useState<WhatsAppMensaje[]>([]);
    const [mensajesWhatsApp, setMensajesWhatsApp] = useState<WhatsAppMensaje>(WhatsAppModel.getEstructura());
    const [isOpenModalChat, setIsOpenModalChat] = useState<boolean>(false);
    const [editarChat, setEditarChat] = useState<boolean>(false);
    const [hora, setHora] = useState<string>(Tiempo.getCurrentTime());
    const [arrayErrores, setArrayErrores] = useState<string[]>([]);
    const [isOpenModalErrores, setIsOpenModalErrores] = useState<boolean>(false);

    useEffect(() => {
        listarMensajesWhatsApp(0);
    }, []);

    const listarMensajesWhatsApp = async (IdWhatsAppMensaje: number): Promise<void> => {
        setHora("Cargando datos...");
        const response: Response = await WhatsAppService.listarMensajes(IdWhatsAppMensaje);
        if (response.error === false) {
            if (IdWhatsAppMensaje === 0) {
                const mensajesWhatsAppLista: WhatsAppMensaje[] = response.data.value;
                setMensajesWhatsAppLista(mensajesWhatsAppLista);
                setHora(Tiempo.getCurrentTimeFormat12Hour());
            }
            if (IdWhatsAppMensaje > 0) {
                const mensajesWhatsApp: WhatsAppMensaje = response.data.value;
                setMensajesWhatsApp(mensajesWhatsApp);
            }

        }
    }

    const validarDatos = (): boolean => {

        const { titulo, texto, descripcion } = mensajesWhatsApp;
        let arrayErrores: string[] = [];
        let value: boolean = true;

        if (titulo.length === 0) { value = false; arrayErrores.push("Falta especificar el titulo"); }
        if (texto.length === 0) { value = false; arrayErrores.push("Falta especificar el mensaje que verán los clientes"); }
        if (descripcion.length === 0) { value = false; arrayErrores.push("Falta especificar la descripción"); }

        setArrayErrores(arrayErrores);

        return value;
    }

    const guardarMensajeWhatsApp = async (): Promise<void> => {
        if (validarDatos()) {
            const response = await WhatsAppService.guardarMensajes(mensajesWhatsApp);
            if (response.data.error === false) {
                const IdWhatsAppMensaje: number = +response.data.value
                setMensajesWhatsApp((prev: WhatsAppMensaje) => ({ ...prev, IdWhatsAppMensaje }));
                listarMensajesWhatsApp(0);
                toast.success("Datos guardados");
            }
        } else {
            setIsOpenModalErrores(true);
            toast.warn("No se pudieron guardar los datos");
        }
    }

    const eliminarMensajeWhatsApp = async (mensajesWhatsApp: WhatsAppMensaje): Promise<void> => {
        Swal.fire({
            icon: "question",
            title: "Eliminar Mensaje",
            text: `¿Quieres eliminar a esta plantilla de mensaje de WhatsApp (${mensajesWhatsApp.titulo})?`,
            showConfirmButton: true,
            confirmButtonColor: colors.blue,
            denyButtonColor: colors.blue,
            cancelButtonText: "No",
            confirmButtonText: "Si",
            reverseButtons: true,
            showCancelButton: true,
            timer: 10000,
            timerProgressBar: true,
            footer: ` <label> <i> No podrás recuperar los datos una ves borrado </i> </label>`
        }).then(async (response) => {
            if (response.isConfirmed) {
                await WhatsAppService.eliminarMensajes(mensajesWhatsApp.IdWhatsAppMensaje);
                listarMensajesWhatsApp(0);
            }
        })

    }

    return (
        <>
            <main style={{ width: "100%", minHeight: "100vh", height: "100vh", maxHeight: "auto", backgroundColor: colors.white01 }} >
                <ToastContainer limit={3} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
                <section style={{ marginLeft: data.isDesktop ? 80 : 0 }} >
                    <Block height={"2vh"} />
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col>
                            <Label style={{ fontSize: 30, }} >
                                CONFIGURACIÓN DE MENSAJES
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <img src={imgTzedakaLogo} alt="/*-" style={{ height: 50, marginRight: 15 }} />
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 15 }} >
                        <Col>
                            <Label style={{ fontWeight: "bold" }} >
                                Ultima Actualización: {hora}
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <IconButton
                                style={{ backgroundColor: colors.white01, color: colors.blue, marginRight: 7 }}
                                onClick={() => { listarMensajesWhatsApp(0); }}
                            >
                                <Cached />
                            </IconButton>
                            <IconButton
                                style={{ backgroundColor: colors.white01, color: colors.blue, marginRight: 15 }}
                                onClick={() => { setIsOpenModalChat(true); setMensajesWhatsApp(WhatsAppModel.getEstructura()); setEditarChat(true); }}
                            >
                                <Add />
                            </IconButton>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10, }} >
                        {
                            mensajesWhatsAppLista.map((item: WhatsAppMensaje) => {
                                return (
                                    <Col key={item.IdWhatsAppMensaje} sm="12" md="6" lg="3" className="mb-4"  >
                                        <Card className="custom-card" style={{ backgroundColor: colors.white01, padding: 10, height: "30vh", }}>
                                            <FormGroup>
                                                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                                                    <Col style={{ minWidth: "80%" }} >
                                                        <Label style={{ color: colors.black, fontSize: 16, fontWeight: "bold", marginTop: 12 }} >
                                                            {item.titulo}
                                                        </Label>
                                                    </Col>
                                                    <Col style={{ minWidth: "20%", textAlign: "right" }} >
                                                        <IconButton
                                                            style={{ color: colors.blue }}
                                                            onClick={() => {
                                                                setIsOpenModalChat(true);
                                                                setMensajesWhatsApp(item);
                                                            }}
                                                        >
                                                            <Chat />
                                                        </IconButton>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                                                    <Col>
                                                        <Label
                                                            style={{ color: colors.blue, textDecorationLine: "underline", marginLeft: 0, textUnderlineOffset: "4px", cursor: "pointer" }}
                                                            onClick={() => {
                                                                setIsOpenModalChat(true);
                                                                setMensajesWhatsApp(item);
                                                                setEditarChat(true);
                                                            }}
                                                        >
                                                            Editar
                                                        </Label>
                                                        <Label
                                                            style={{ color: colors.red, textDecorationLine: "underline", marginLeft: 7, textUnderlineOffset: "4px", cursor: "pointer" }}
                                                            onClick={() => {
                                                                eliminarMensajeWhatsApp(item);
                                                            }}
                                                        >
                                                            Eliminar
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <DropdownItem divider />
                                                <Row style={{ marginRight: 0, marginLeft: 0, overflowY: "auto" }} >
                                                    <Col>
                                                        <Label>
                                                            {item.descripcion}
                                                        </Label>
                                                    </Col>
                                                </Row>

                                            </FormGroup>

                                        </Card>

                                    </Col>
                                )
                            })
                        }
                    </Row >
                </section>
                <Modal
                    isOpen={isOpenModalChat}
                    style={{ fontFamily: "Montserrat", maxWidth: 600 }}
                    size="lg"
                >
                    <ModalHeader
                        style={{ backgroundColor: colors.blue }}
                        close={
                            <Col style={{ textAlign: "right" }} >
                                {
                                    editarChat ?
                                        <Label
                                            style={{ color: colors.white01, boxShadow: "none", borderColor: colors.blue, cursor: "pointer", textDecorationLine: "underline", textUnderlineOffset: "4px" }}
                                            onClick={() => {
                                                setEditarChat(false);
                                            }}
                                        >
                                            Ver vista previa
                                        </Label>
                                        :
                                        <>
                                            <IconButton
                                                style={{ backgroundColor: colors.white01, color: colors.blue, marginRight: 7 }}
                                                onClick={() => {
                                                    setEditarChat(true);
                                                }}
                                            >
                                                <Edit />
                                            </IconButton>
                                            <IconButton
                                                style={{ backgroundColor: colors.white01, color: colors.blue }}
                                                onClick={() => {
                                                    setIsOpenModalChat(false);
                                                }}
                                            >
                                                <Close />
                                            </IconButton>
                                        </>
                                }

                            </Col>
                        }
                    >
                        <Label style={{ color: colors.white01 }} >{editarChat ? "DATOS DEL CHAT" : "VISTA PREVIA"}</Label>
                    </ModalHeader>
                    <ModalBody style={{ minHeight: "70vh", height: "70vh", maxHeight: "70vh", overflowY: "scroll" }} >
                        {
                            editarChat ?
                                <>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 18, }} >
                                                    Titulo
                                                </Label>
                                                <Input
                                                    id="mensajesWhatsApp.titulo"
                                                    type="text"
                                                    name="titulo"
                                                    value={mensajesWhatsApp.titulo}
                                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setMensajesWhatsApp((prev: WhatsAppMensaje) => ({ ...prev, [name]: value }));
                                                    }}
                                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                        if (key === "Enter") Components.focus("");
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                    style={{ ...Styles.input }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 18, }} >
                                                    Mensaje que vera el cliente
                                                </Label>
                                                <Input
                                                    id="mensajesWhatsApp.texto"
                                                    type="textarea"
                                                    name="texto"
                                                    value={mensajesWhatsApp.texto}
                                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setMensajesWhatsApp((prev: WhatsAppMensaje) => ({ ...prev, [name]: value }));
                                                    }}
                                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                        if (key === "Enter") Components.focus("");
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                    style={{ minHeight: 200, height: 200, maxHeight: 200, boxShadow: "none", borderColor: colors.blue }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 18, }} >
                                                    Descripción
                                                </Label>
                                                <Input
                                                    id="mensajesWhatsApp.descripcion"
                                                    type="textarea"
                                                    name="descripcion"
                                                    value={mensajesWhatsApp.descripcion}
                                                    onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                                        setMensajesWhatsApp((prev: WhatsAppMensaje) => ({ ...prev, [name]: value }));
                                                    }}
                                                    onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                        if (key === "Enter") Components.focus("");
                                                    }}
                                                    onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                        event.target.select();
                                                    }}
                                                    style={{ minHeight: 200, height: 200, maxHeight: 200, boxShadow: "none", borderColor: colors.blue }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </>
                                :
                                <>
                                    <Row>
                                        <Label style={{ fontSize: 18 }} >
                                            <strong>Titulo:</strong> {mensajesWhatsApp.titulo}
                                        </Label>
                                    </Row>
                                    <Row style={{ marginTop: 10 }} >
                                        <Label>Descripción</Label>
                                        <p>
                                            <i> {mensajesWhatsApp.descripcion}</i>
                                        </p>
                                    </Row>
                                    <center>
                                        <WhatsAppMessage mensajesWhatsApp={mensajesWhatsApp} />
                                    </center>
                                    <Block height={30} />
                                </>
                        }
                    </ModalBody>
                    {
                        editarChat &&
                        <ModalFooter>
                            <Button
                                style={{ ...Styles.button, width: 120, backgroundColor: colors.white01, borderColor: colors.blue, color: colors.blue }}
                                onClick={() => setIsOpenModalChat(false)}
                            >
                                Cerrar
                            </Button>
                            <Button
                                style={{ ...Styles.button, width: 120 }}
                                onClick={() => guardarMensajeWhatsApp()}
                            >
                                Guardar
                            </Button>
                        </ModalFooter>
                    }
                </Modal>
                <Modal
                    isOpen={isOpenModalErrores}
                    style={{ fontFamily: "Montserrat", maxWidth: "35%" }}
                >
                    <ModalHeader
                        style={{ backgroundColor: colors.blue }}
                        close={
                            <IconButton
                                style={{ backgroundColor: colors.white01, color: colors.blue }}
                                onClick={() => {
                                    setIsOpenModalErrores(false);
                                }}
                            >
                                <Close />
                            </IconButton>
                        }
                    >
                        <Label style={{ color: colors.white01 }} >No se pudo guardar al usuario</Label>
                    </ModalHeader>
                    <ModalBody>
                        {
                            arrayErrores.map((error: string, index: number) => {
                                return (
                                    <>
                                        <Row>
                                            <Col>
                                                <Label style={{ fontSize: 18 }} >
                                                    {index + 1}.- {error}
                                                </Label>
                                            </Col>
                                        </Row>
                                        <DropdownItem divider />
                                    </>
                                );
                            })
                        }
                    </ModalBody>
                </Modal>
            </main >
        </>
    )

}

export default WhatsAppConfig;