import ApiConfig from "../config/Api.config";
import Correo from "../interfaces/CarcaCorreos.interface";
import ContactoWhatsApp from "../interfaces/ContactoWhatsApp.interface";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";

const url = `${ApiConfig.URLBase}/carga-correos`
const urlS= `${ApiConfig.URLBase}/correos`

const listar = async (texto: string): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar`,
        metodo: "POST",
        data: { texto }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const cambiar = async (array: Correo[]): Promise<Response> => {
    const request: Request = {
        url: `${url}/cambiar`,
        metodo: "PUT",
        data: { array }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const cambiarDatoWhatsApp = async (array: ContactoWhatsApp[]): Promise<Response> => {
    const request: Request = {
        url: `${url}/whatsApp/cambiar`,
        metodo: "PUT",
        data: { array }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const mandar = async (correo: Correo, value: string): Promise<Response> => {
    const request: Request = {
        url: `${urlS}/mandar`,
        metodo: "POST",
        data: { correo, value }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};


const CorreosService = {
    listar,
    cambiar,
    mandar,
    cambiarDatoWhatsApp,
};

export default CorreosService;