import EstadoCivil from "../interfaces/EstadoCivil.interface"

const getEstructura = (): EstadoCivil => {
    const estadoCivil: EstadoCivil = {
        IdEstadoCivil: 0,
        estadoCivil: ""
    }
    return estadoCivil;
}

const EstadoCivilModel = {
    getEstructura
}

export default EstadoCivilModel