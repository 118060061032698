import { FC, useState, ChangeEvent, FocusEvent, KeyboardEvent, useEffect } from "react";
import DeviceConfig from "../../../config/Device.config";
import Device from "../../../interfaces/Device.interface";
import colors from "../../../config/Colors.config";
import Block from "../../../components/Block.component";
import { Col, DropdownItem, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Tiempo from "../../../tools/Tiempo.tool";
import ClienteService from "../../../services/Cliente.service";
import Response from "../../../interfaces/Response.interface";
import ClienteCorto from "../../../interfaces/ClienteCorto.interface";
import Components from "../../../tools/Components.tool";
import { Add, Cached, Close, Delete, Edit, Search, DeleteOutline } from "@material-ui/icons";
import Styles from "../../../assets/css/Styles.css";
import Table, { TableColumn } from 'react-data-table-component';
import { IconButton } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import imgBuscarCliente from "../../../assets/img/imgBuscarCliente.svg";
import iconPDF from "../../../assets/icon/iconPDF.png";
// import iconDelete from "../../../assets/icon/borrar.png";
import { Article, Person } from "@mui/icons-material";
import Number from "../../../tools/Number.tool";
import queryString from 'query-string';
import { ToastContainer, toast } from "react-toastify";
import imgTzedakaLogo from "../../../assets/img/logoTzedaka.png";
import { SolicitudCredito, MontoSolicitado, PlazoCredito, FormaPago, Status } from "../../../interfaces/SolicitudCredito.interface";
import SolicitudCreditoService from "../../../services/SolicitudCredito.service";
import Fecha from "../../../tools/Fecha.tool";
import Moneda from "../../../tools/Moneda.tool";
import SolicitudCreditoModel from "../../../models/SolicitudCredito.model";
import Swal from "sweetalert2";
import { PDFViewer } from "@react-pdf/renderer";
import SolicitudCreditoPdf from "../../../reports/SolicitudCredito.report";
import ClienteModel from "../../../models/Cliente.model";
import Cliente from "../../../interfaces/Cliente.interface";
import LoadingOverlay from 'react-loading-overlay-ts';
import UsuarioModel from "../../../models/Usuario.model";
import Usuario from "../../../interfaces/Usuario.interface";


const ListaCliente: FC<{ changeIndexPage: any }> = ({ changeIndexPage }) => {
    const [IdClienteGlobal, setIdClienteGlobal] = useState<string>("");
    const [nombreCliente, setNombreCliente] = useState<string>("");
    const [clienteLista, setClienteLista] = useState<ClienteCorto[]>([]);
    const [solicitudCreditoLista, setSolicitudCreditoLista] = useState<SolicitudCredito[]>([]);
    const [montosLista, setMontosLista] = useState<MontoSolicitado[]>([]);
    const [plazoCreditoLista, setPlazoCreditoLista] = useState<PlazoCredito[]>([]);
    const [formaPagoLista, setFormaPagoLista] = useState<FormaPago[]>([]);
    const [statusLista, setStatusLista] = useState<Status[]>([]);
    const [solicitudCredito, setSolicitudCredito] = useState<SolicitudCredito>(SolicitudCreditoModel.getEstructura());
    const [cliente, setCliente] = useState<Cliente>(ClienteModel.getEstructura());
    const [textBuscar, setTextBuscar] = useState<string>("");
    const [cantidadClientes, setCantidadClientes] = useState<string>(" /*- Clients");
    const [hora, setHora] = useState<string>(Tiempo.getCurrentTime());
    const [isOpenModalSolicitudCredito, setIsOpenModalSolicitudCredito] = useState<boolean>(false);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [isOpenModalPDF, setIsOpenModalPDF] = useState<boolean>(false);
    const [isOpenModalErrores, setIsOpenModalErrores] = useState<boolean>(false);
    const [erroresLabels, setErroresLabels] = useState<string[]>([]);
    const [isLoadPDF, setIsLoadPDF] = useState<boolean>(false);
    const [nombreUsuario, setNombreUsuario] = useState<string>("")

    const [data] = useState<Device>(DeviceConfig.data());

    const location = useLocation();
    const navigate = useNavigate();

    // const listarClientes = async (text: string): Promise<void> => {
    //     setHora("Buscando clientes...");
    //     const response: Response = await ClienteService.listar(text);
    //     const clienteLista: ClienteCorto[] = response.data.value;
    //     setClienteLista(clienteLista);
    //     const count: number = clienteLista.length;
    //     if (count === 0) toast.info("No se encontraron clientes");
    //     let letras: string;
    //     if (count > 1) {
    //         letras = `Se encontraron ${count} clientes`;
    //     } else {
    //         letras = `Se encontró un clientes`;
    //     }
    //     setCantidadClientes(letras);
    //     setHora(Tiempo.getCurrentTimeFormat12Hour());
    //     navigate(`/dashboard/?page=1&text=${textBuscar}`);
    // };

    const listarClientes = async (text: string): Promise<void> => {
        setHora("Buscando clientes...");
        const response: Response = await ClienteService.listar(text);
        const clienteLista: ClienteCorto[] = response.data.value;
        setClienteLista(clienteLista);

        const count: number = clienteLista.length;

        if (count === 0) toast.info("No se encontraron clientes");

        const letras: string = `Se ${count === 1 ? "encontró" : "encontraron"} ${count} cliente${count === 1 ? "" : "s"}`;

        setCantidadClientes(letras);
        setHora(Tiempo.getCurrentTimeFormat12Hour());
        navigate(`/dashboard/?page=1&text=${textBuscar}`);

    };

    const obtenerSolicitudCredito = async (IdCliente: string): Promise<void> => {
        const response: Response = await SolicitudCreditoService.obtener(IdCliente);
        const solicitudCredito: SolicitudCredito[] = response.data.value;
        setSolicitudCreditoLista(solicitudCredito);
    }

    const guardarSolicitudCredito = async (): Promise<void> => {
        const { esValido, erroresLabel } = SolicitudCreditoModel.validarDatos(solicitudCredito);
        if (esValido) {
            const response: Response = await SolicitudCreditoService.guardar(solicitudCredito);
            if (response.error === false) {
                toast.info("Datos guardados");
                setSolicitudCredito((prev: SolicitudCredito) => ({ ...prev, IdSolicitudCredito: response.data.value }));
                obtenerSolicitudCredito(solicitudCredito.IdCliente);
            }
        } else {
            setErroresLabels(erroresLabel);
            setIsOpenModalErrores(true);
        }

    }

    const eliminarSolicitudCredito = async (solicitudCredito: SolicitudCredito): Promise<void> => {

        Swal.fire({
            icon: "question",
            title: "Eliminar Solicitud de Credito",
            text: "¿Estas seguro de eliminar esta solicitud de crédito?",
            showConfirmButton: true,
            confirmButtonColor: colors.blue,
            denyButtonColor: colors.blue,
            cancelButtonText: "No",
            confirmButtonText: "Si",
            reverseButtons: true,
            showCancelButton: true,
            timer: 10000,
            timerProgressBar: true,
            footer: ` <label> <i> No podrás recuperar los datos una vez eliminados </i> </label>`
        }).then(async (response) => {
            if (response.isConfirmed) {
                const response: Response = await SolicitudCreditoService.eliminar(solicitudCredito.IdSolicitudCredito);
                if (response.error === false) {
                    toast.info("Datos eliminados");
                    obtenerSolicitudCredito(solicitudCredito.IdCliente);
                }
            }
        })

    }

    const cargarURL = () => {
        const parsedUrl = queryString.parseUrl(location.search);
        const { page, texto } = parsedUrl.query;
        if (texto !== undefined && texto !== "") {
            setTextBuscar(`${texto}`);
            listarClientes(`${texto}`);
            navigate(`/dashboard/?page=${page}&texto=${texto}`);
        }

        Components.focus("textBuscar");
    }

    const apuntadorMontoTabla = (solicitudCredito: SolicitudCredito): string => {
        const IdStatus: number = solicitudCredito.status.IdStatus;
        const montoAprobado: string = solicitudCredito.montoAprobado;
        let text: string = ""
        if (IdStatus === 1) text = "Pendiente";
        if (IdStatus === 2) text = Moneda.formatCurrency(montoAprobado.toString());
        if (IdStatus === 3 || IdStatus === 4) text = "---";
        return text;
    }

    useEffect(() => {
        cargarURL();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const listarMontos = async (): Promise<void> => {
        const response: Response = await SolicitudCreditoService.listarMontos();
        if (response.error === false) {
            const montosLista: MontoSolicitado[] = response.data.value;
            setMontosLista(montosLista);
        }
    }

    const listarPlazoCredito = async (): Promise<void> => {
        const response: Response = await SolicitudCreditoService.listarPlazoCredito();
        if (response.error === false) {
            const plazoCreditoLista: PlazoCredito[] = response.data.value;
            setPlazoCreditoLista(plazoCreditoLista);
        }
    }

    const listarFormaPago = async (): Promise<void> => {
        const response: Response = await SolicitudCreditoService.listarFormaPago();
        if (response.error === false) {
            const formaPagoLista: FormaPago[] = response.data.value;
            setFormaPagoLista(formaPagoLista);
        }
    }

    const listarStatus = async (): Promise<void> => {
        const response: Response = await SolicitudCreditoService.listarStatus();
        if (response.error === false) {
            const statusLista: Status[] = response.data.value;
            setStatusLista(statusLista)
        }
    }

    const getColor = (index: number): string => {
        const arrayColors = [colors.blue, colors.green01, colors.red, colors.grey];
        return arrayColors[(index - 1)];
    }

    const columnsClientes: TableColumn<ClienteCorto>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > Num. </Label>,
            cell: (clienteCorto: ClienteCorto, index: number) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{index + 1}</Label>,
            width: "100px"
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > CLIENTE </Label>,
            cell: (clienteCorto: ClienteCorto) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{clienteCorto.nombres}</Label>,
            width: "400px"
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > CORREO </Label>,
            cell: (clienteCorto: ClienteCorto) => <Label style={{ color: colors.link, fontSize: 16, whiteSpace: 'normal', cursor: "pointer" }} onClick={() => { window.location.href = `mailto:${clienteCorto.correo.toLowerCase()}` }}>{clienteCorto.correo.toLowerCase()}</Label>,
            width: "300px"
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > TELÉFONO </Label>,
            cell: (clienteCorto: ClienteCorto) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal', textDecorationLine: "underline" }} onClick={() => { window.location.href = `tel:${clienteCorto.telefono}` }} >{Number.formatPhoneNumber(clienteCorto.telefono)}</Label>,
            width: "150px"
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > OCUPACIÓN </Label>,
            cell: (clienteCorto: ClienteCorto) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{clienteCorto.esTrabajador === 1 ? "ES TRABAJADOR" : "TIENE NEGOCIO"}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18, whiteSpace: 'normal' }} >  </Label>,
            cell: (clienteCorto: ClienteCorto) =>
                <>
                    <IconButton
                        style={{ backgroundColor: colors.white01, color: colors.blue }}
                        onClick={() => {
                            setIsOpenModalSolicitudCredito(true);
                            obtenerSolicitudCredito(clienteCorto.IdCliente);
                            setIdClienteGlobal(clienteCorto.IdCliente);
                            setNombreCliente(clienteCorto.nombres)
                        }}
                    >
                        <Article />
                    </IconButton>
                    <IconButton
                        style={{ backgroundColor: colors.white01, color: colors.blue, marginLeft: 10 }}
                        onClick={() => {
                            navigate(`/dashboard/?page=1&uuid=${clienteCorto.IdCliente}`);
                            changeIndexPage(2);
                        }}
                    >
                        <Person />
                    </IconButton>
                </>
            ,
        },
    ]

    const columnsSolicitudCredito: TableColumn<SolicitudCredito>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > Num. </Label>,
            cell: (solicitudCredito: SolicitudCredito, index: number) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{index + 1}</Label>,
            width: "100px"
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > FECHA </Label>,
            cell: (solicitudCredito: SolicitudCredito) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{Fecha.formatDate(solicitudCredito.fechaCreacion)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > TIPO </Label>,
            cell: (solicitudCredito: SolicitudCredito) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{solicitudCredito.tipoCredito.tipoCredito}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > ESTATUS </Label>,
            cell: (solicitudCredito: SolicitudCredito) => <Label style={{ color: getColor(solicitudCredito.status.IdStatus), fontSize: 16, whiteSpace: 'normal', fontWeight: "bold" }} >{solicitudCredito.status.status}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > MONTO SOLICITADO </Label>,
            cell: (solicitudCredito: SolicitudCredito) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{Moneda.formatCurrency(solicitudCredito.montoSolicitado.montoSolicitado)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > MONTO APROBADO </Label>,
            cell: (solicitudCredito: SolicitudCredito) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{apuntadorMontoTabla(solicitudCredito)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18, whiteSpace: 'normal' }} >  </Label>,
            cell: (solicitudCredito: SolicitudCredito) =>
                <>
                    <IconButton
                        style={{ backgroundColor: colors.white01 }}
                        title={"Eliminar"}
                        onClick={() => {
                            if (solicitudCredito.status.IdStatus !== 2) {
                                eliminarSolicitudCredito(solicitudCredito);
                            } else {
                                Swal.fire({
                                    icon: "info",
                                    title: "Atención",
                                    text: "No se puede eliminar una solicitud de crédito aprobada",
                                    showConfirmButton: true,
                                    confirmButtonColor: colors.blue,
                                    denyButtonColor: colors.blue,
                                    // cancelButtonText: "No",
                                    confirmButtonText: "Ok",
                                    reverseButtons: true,
                                    // showCancelButton: true,
                                    timer: 10000,
                                    timerProgressBar: true,
                                    // footer: ` <label> <i> No podrás recuperar los datos una vez eliminados </i> </label>`
                                });
                            }
                        }}
                    >
                        {
                            solicitudCredito.status.IdStatus !== 2 ?
                                <Delete style={{ color: colors.red }} />
                                :
                                <DeleteOutline style={{ color: colors.grey }} />
                        }
                    </IconButton>
                    <IconButton
                        style={{ backgroundColor: colors.white01, color: colors.blue, marginLeft: 0 }}
                        title={"Generar PDF"}
                        onClick={async () => {
                            setSolicitudCredito(solicitudCredito);
                            setIsLoadPDF(true);
                            setIsOpenModalPDF(true)
                            const response: Response = await ClienteService.obtener(solicitudCredito.IdCliente);
                            const usuario: Usuario = await UsuarioModel.getUsuario();
                            const nombreUsuario: string = usuario.nombreCompleto;
                            const cliente: Cliente = response.data.value;
                            setCliente(cliente);
                            setNombreUsuario(nombreUsuario);
                            await Tiempo.sleep(2).then(() => { setIsLoadPDF(false); });

                        }}
                    >
                        <img src={iconPDF} alt="NO DATA" style={{ height: 20 }} />
                    </IconButton>
                    <IconButton
                        style={{ backgroundColor: colors.white01, color: colors.blue, marginLeft: 0 }}
                        title={"Ver o Actualizar Datos"}
                        onClick={() => {
                            setIsOpenModal(true);
                            setSolicitudCredito({ ...solicitudCredito, montoAprobado: Moneda.castNumeroConDecimales(solicitudCredito.montoAprobado) });
                        }}
                    >
                        <Edit />
                    </IconButton>
                </>
            ,
        },
    ]

    return (
        <>
            <ToastContainer position="bottom-right" limit={3} autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
            <main style={{ width: "100%", minHeight: "100vh", /*height:/* "100vh", maxHeight: "auto",*/ backgroundColor: colors.white01 }} >
                <section style={{ marginLeft: data.isDesktop ? 80 : 0, position: "relative" }} >
                    <div style={{ /*position: data.isDesktop ? "fixed" : "relative", */backgroundColor: colors.white01, zIndex: 2, width: data.isDesktop ? "95%" : "" }} >
                        <Block height={"2vh"} />
                        <Row style={{ marginRight: 0, marginLeft: 0 }} >
                            <Col>
                                <Label style={{ fontSize: 30, }} >
                                    CLIENTES DADOS DE ALTA EN EL SISTEMA
                                </Label>
                            </Col>
                            {
                                data.isDesktop &&
                                <Col style={{ textAlign: "right" }} >
                                    <img src={imgTzedakaLogo} alt="/*-" style={{ height: 50, marginRight: 15 }} />
                                </Col>
                            }

                        </Row>
                        <DropdownItem divider />
                        <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                            <Col style={{ minWidth: "50%", width: "50%", maxWidth: "50%" }} >
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                        Buscar Cliente
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="textBuscar"
                                            type="text"
                                            placeholder="Nombres, Apellido Paterno, Apellido Materno"
                                            value={textBuscar}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                setTextBuscar(value)
                                            }}
                                            onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                if (key === "Enter") listarClientes(textBuscar);
                                            }}
                                            onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                event.target.select();
                                            }}
                                            style={Styles.input}
                                        />
                                        <InputGroupText
                                            style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white01, cursor: "pointer" }}
                                            onClick={() => {
                                                setTextBuscar("");
                                                Tiempo.sleep(1).then(() => { setClienteLista([]) });
                                                Components.focus("textBuscar");
                                                navigate(`/dashboard/?page=1`);
                                            }}
                                        >
                                            <Close />
                                        </InputGroupText>
                                        <InputGroupText
                                            style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white01, cursor: "pointer" }}
                                            onClick={() => {
                                                // listarClientes(textBuscar);
                                                // Components.focus("textBuscar");
                                                if (textBuscar.length > 0) {
                                                    listarClientes(textBuscar);
                                                } else {
                                                    toast.warning("No has especificado la búsqueda");
                                                    Components.focus("textBuscar");
                                                }
                                            }}
                                        >
                                            <Search />
                                        </InputGroupText>
                                    </InputGroup>
                                    <Label
                                        style={{ marginTop: 5, color: colors.blue, cursor: "pointer", textDecorationLine: "underline" }}
                                        onClick={() => listarClientes("")}
                                    >
                                        Listar a todos los cientes
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col style={{ textAlign: "right" }} >
                                <Block height={40   } />
                                <IconButton
                                    title={"Agregar Cliente"}
                                    onClick={() => {
                                        changeIndexPage(2);
                                    }}
                                    style={{ marginRight: 20, color: colors.blue }}
                                >
                                    <Add />
                                </IconButton>
                                <IconButton
                                    title={"Actualizar Tabla de Clientes"}
                                    style={{ marginRight: 20, color: colors.blue }}
                                    onClick={() => {
                                        if (textBuscar.length > 0) {
                                            listarClientes(textBuscar);
                                        } else {
                                            toast.warning("No has especificado la búsqueda");
                                            Components.focus("textBuscar");
                                        }
                                    }}
                                >
                                    <Cached />
                                </IconButton>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                            <Col>
                                <Label style={{ fontWeight: "bold" }} >
                                    Ultima Actualización: {hora}
                                </Label>
                            </Col>
                        </Row>
                        <Block height={10} />
                    </div>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 0 }} >
                        <Col>
                            {
                                clienteLista.length === 0 ?
                                    <center>
                                        <Block height={data.isDesktop ? 10 : 0} />
                                        <img src={imgBuscarCliente} alt="/*-" style={{ height: "50vh" }} />
                                    </center>
                                    :
                                    <>
                                        <Block height={data.isDesktop ? 10 : 0} />
                                        <Table
                                            title={<Label>{cantidadClientes}</Label>}
                                            columns={columnsClientes}
                                            data={clienteLista}
                                        //fixedHeader
                                        />
                                    </>
                            }
                        </Col>
                    </Row>
                    <Block height={"10vh"} />
                </section>
            </main >
            <Modal
                isOpen={isOpenModalSolicitudCredito}
                size="lg"
                style={{ fontFamily: "Montserrat", maxWidth: "70%" }}
            >
                <ModalHeader
                    style={{ backgroundColor: colors.blue }}
                    close={
                        <Col style={{ textAlign: "right" }} >
                            <IconButton
                                style={{ backgroundColor: colors.white01, color: colors.blue, marginRight: 10 }}
                                onClick={async () => {
                                    const IdCliente: string = IdClienteGlobal;
                                    setIsOpenModal(true);
                                    setSolicitudCredito({ ...SolicitudCreditoModel.getEstructura(), IdCliente, fechaCreacion: Fecha.getDateToDay() });
                                }}
                            >
                                <Add />
                            </IconButton>
                            <IconButton
                                style={{ backgroundColor: colors.white01, color: colors.blue, marginRight: 10 }}
                                onClick={async () => {
                                    obtenerSolicitudCredito(IdClienteGlobal);
                                }}
                            >
                                <Cached />
                            </IconButton>
                            <IconButton
                                style={{ backgroundColor: colors.white01, color: colors.blue }}
                                onClick={() => {
                                    setIsOpenModalSolicitudCredito(false);
                                }}
                            >
                                <Close />
                            </IconButton>
                        </Col>
                    }
                >
                    <Label style={{ color: colors.white01, fontSize: 24 }} >
                        Solicitudes de Créditos
                    </Label>
                </ModalHeader>
                <ModalBody style={{ minHeight: data.isDesktop ? "70vh" : "", height: data.isDesktop ? "70vh" : "", maxHeight: data.isDesktop ? "70vh" : "", overflowY: "auto" }} >
                    <Table
                        title={<Label>Cliente: {nombreCliente.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}</Label>}
                        columns={columnsSolicitudCredito}
                        data={solicitudCreditoLista}
                    //fixedHeader
                    />
                </ModalBody>

            </Modal>
            <Modal
                isOpen={isOpenModal}
                // size="lg"
                style={{ fontFamily: "Montserrat", maxWidth: "50%" }}
                onOpened={() => {
                    listarMontos();
                    listarPlazoCredito();
                    listarFormaPago();
                    listarStatus();
                }}
                onClosed={() => {
                    setSolicitudCredito(SolicitudCreditoModel.getEstructura());
                }}
            >
                <ModalHeader
                    style={{ backgroundColor: colors.blue }}
                    close={
                        <Col style={{ textAlign: "right" }} >
                            <IconButton
                                style={{ backgroundColor: colors.white01, color: colors.blue }}
                                onClick={() => {
                                    setIsOpenModal(false);
                                    setSolicitudCredito(SolicitudCreditoModel.getEstructura());
                                }}
                            >
                                <Close />
                            </IconButton>
                        </Col>
                    }
                >
                    <Label style={{ color: colors.white01, fontSize: 24 }} >
                        Datos de la Solicitud de Crédito
                    </Label>
                </ModalHeader>
                <ModalBody style={{ minHeight: data.isDesktop ? "70vh" : "", height: data.isDesktop ? "70vh" : "", maxHeight: data.isDesktop ? "70vh" : "", overflowY: "auto" }} >
                    <Row>
                        <Col>
                            <Label style={{ fontSize: 24 }} >
                                Cliente: {nombreCliente.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                            </Label>
                        </Col>
                    </Row>
                    <DropdownItem divider />
                    <Label>{solicitudCredito.IdSolicitudCredito}</Label>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label style={{ fontWeight: "bold" }} >
                                    Fecha
                                </Label>
                                <Input
                                    style={Styles.input}
                                    value={solicitudCredito.fechaCreacion}
                                    type="date"
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setSolicitudCredito((prev: SolicitudCredito) => ({ ...prev, fechaCreacion: value }));
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label style={{ fontWeight: "bold" }} >
                                    Tipo de Solicitud Credito
                                </Label>
                                <Input
                                    style={Styles.input}
                                    value={solicitudCredito.tipoCredito.IdTipoCredito}
                                    type="select"
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setSolicitudCredito((prev: SolicitudCredito) => ({ ...prev, tipoCredito: { ...prev.tipoCredito, IdTipoCredito: +value } }));
                                    }}
                                >
                                    <option value={0}>Seleccione una opción</option>
                                    <option value={1}>Individual</option>
                                    <option value={2}>Grupal</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    {/* <Row>
                        
                    </Row> */}
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label style={{ fontWeight: "bold" }} >
                                    Monto Solicitado
                                </Label>
                                <Input
                                    style={Styles.input}
                                    value={solicitudCredito.montoSolicitado.IdMontoSolicitado}
                                    type="select"
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setSolicitudCredito((prev: SolicitudCredito) => ({ ...prev, montoSolicitado: { ...prev.montoSolicitado, IdMontoSolicitado: +value } }));
                                    }}
                                >
                                    <option value={0}>Seleccione una opción</option>
                                    {
                                        montosLista.map((monto: MontoSolicitado) => {
                                            return (<option value={monto.IdMontoSolicitado} >{Moneda.formatCurrency(monto.montoSolicitado).replace("$", "$ ")}</option>)
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label style={{ fontWeight: "bold" }} >
                                    Plazo de Pagos
                                </Label>
                                <Input
                                    style={Styles.input}
                                    value={solicitudCredito.plazoCredito.IdPlazoCredito}
                                    type="select"
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setSolicitudCredito((prev: SolicitudCredito) => ({ ...prev, plazoCredito: { ...prev.plazoCredito, IdPlazoCredito: +value } }));
                                    }}
                                >
                                    <option value={0}>Seleccione una opción</option>
                                    {
                                        plazoCreditoLista.map((plazo: PlazoCredito) => {
                                            return (<option value={plazo.IdPlazoCredito} >{plazo.plazoCredito} ({plazo.descripcion})</option>)
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    {/* <Row>
                       
                    </Row> */}
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label style={{ fontWeight: "bold" }} >
                                    Forma de Pago
                                </Label>
                                <Input
                                    style={Styles.input}
                                    value={solicitudCredito.formaPago.IdFormaPago}
                                    type="select"
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setSolicitudCredito((prev: SolicitudCredito) => ({ ...prev, formaPago: { ...prev.formaPago, IdFormaPago: +value } }));
                                    }}
                                >
                                    <option value={0}>Seleccione una opción</option>
                                    {
                                        formaPagoLista.map((formaPago: FormaPago) => {
                                            return (<option value={formaPago.IdFormaPago} >{formaPago.formaPago}</option>)
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label style={{ fontWeight: "bold" }} >
                                    Estatus
                                </Label>
                                <Input
                                    style={Styles.input}
                                    value={solicitudCredito.status.IdStatus}
                                    type="select"
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setSolicitudCredito((prev: SolicitudCredito) => ({ ...prev, status: { ...prev.status, IdStatus: +value } }));
                                    }}
                                >
                                    {
                                        statusLista.map((status: Status) => {
                                            return <option value={status.IdStatus}>{status.status}</option>
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    {/* <Row>
                        
                    </Row> */}
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label style={{ fontWeight: "bold" }} >
                                    Monto Aprobado
                                </Label>
                                <InputGroup>

                                    {
                                        solicitudCredito.status.IdStatus !== 2 &&
                                        <Label style={{ fontSize: 16 }} >
                                            <i> La solicitud Credito no ha sido aprobada </i>
                                        </Label>
                                    }
                                    {
                                        solicitudCredito.status.IdStatus === 2 &&
                                        <>
                                            <InputGroupText
                                                style={{ ...Styles.input, backgroundColor: "transparent", color: colors.black, cursor: "pointer" }}
                                            >
                                                $
                                            </InputGroupText>
                                            <Input
                                                style={Styles.input}
                                                value={solicitudCredito.montoAprobado}
                                                type="text"
                                                onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                    value = Number.singleNumbers(value)
                                                    setSolicitudCredito((prev: SolicitudCredito) => ({ ...prev, montoAprobado: value }));
                                                }}
                                                onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                                    event.target.select();
                                                }}
                                                onBlur={({ target: { value } }: FocusEvent<HTMLInputElement>) => {
                                                    // setCliente((prev: Cliente) => ({ ...prev, gastosPropios: { ...prev.gastosPropios, agua: Moneda.castNumeroConDecimales(value.replace(",", "")) } }));
                                                    setSolicitudCredito((prev: SolicitudCredito) => ({ ...prev, montoAprobado: Moneda.castNumeroConDecimales(value.replace(",", "")) }));
                                                }}
                                            />
                                        </>
                                    }

                                </InputGroup>
                                {
                                    solicitudCredito.status.IdStatus === 2 && <i>{solicitudCredito.status.status} el {Fecha.formatDate(solicitudCredito.fechaModificacion)} </i>
                                }
                            </FormGroup>
                        </Col>

                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{ ...Styles.button, backgroundColor: colors.white01, borderColor: colors.blue, color: colors.blue, width: 120 }}
                        onClick={() => {
                            const IdCliente: string = IdClienteGlobal;
                            setSolicitudCredito({ ...SolicitudCreditoModel.getEstructura(), IdCliente, fechaCreacion: Fecha.getDateToDay() });
                        }}
                    >
                        Nuevo
                    </Button>
                    <Button
                        style={{ ...Styles.button, width: 120 }}
                        onClick={() => {
                            guardarSolicitudCredito();
                        }}
                    >
                        Guardar
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={isOpenModalPDF}
                fullscreen
                style={{ fontFamily: "Montserrat" }}
            >
                <ModalHeader
                    style={{ backgroundColor: colors.pdf.grey01 }}
                    close={
                        <Col style={{ textAlign: "right" }} >
                            <IconButton
                                style={{ backgroundColor: colors.pdf.grey02, color: colors.white01 }}
                                onClick={() => {
                                    setIsOpenModalPDF(false);
                                }}
                            >
                                <Close />
                            </IconButton>
                        </Col>
                    }
                >

                </ModalHeader>
                {
                    isLoadPDF ?
                        <ModalBody style={{ backgroundColor: colors.pdf.grey01, display: "flex", justifyContent: "center", alignItems: "center" }} >
                            {/* <Label style={{ color: colors.white01, fontSize: 42 }} >Generando PDF... </Label> */}
                            <LoadingOverlay
                                active={isLoadPDF}
                                spinner
                                text='Generando PDF...'
                            >

                            </LoadingOverlay>
                        </ModalBody>

                        :
                        <ModalBody style={{ backgroundColor: colors.pdf.grey01 }} >
                            <PDFViewer width="100%" height="800">
                                <SolicitudCreditoPdf solicitudCredito={solicitudCredito} cliente={cliente} nombreUsuario={nombreUsuario} />
                            </PDFViewer>
                        </ModalBody>
                }
            </Modal>
            <Modal
                isOpen={isOpenModalErrores}
                size="lg"
                style={{ maxWidth: "40%" }}
            >
                <ModalHeader
                    style={{ backgroundColor: colors.blue }}
                    close={
                        <Col style={{ textAlign: "right" }} >
                            <IconButton
                                style={{ backgroundColor: colors.white01, color: colors.blue }}
                                onClick={() => {
                                    setIsOpenModalErrores(false);
                                }}
                            >
                                <Close />
                            </IconButton>
                        </Col>
                    }
                >
                    <Label style={{ color: colors.white01, fontSize: 24 }} >
                        ¡Atención!
                    </Label>
                </ModalHeader>
                <ModalBody style={{ minHeight: data.isDesktop ? "70vh" : "", height: data.isDesktop ? "70vh" : "", maxHeight: data.isDesktop ? "70vh" : "", overflowY: "auto" }} >
                    <Row>
                        <Col>
                            <Label style={{ color: colors.black, fontSize: 24 }} >
                                Se encontraron los siguientes errores
                            </Label>
                        </Col>
                    </Row>
                    <DropdownItem divider />
                    {
                        erroresLabels.map((error: string, index: number) => {
                            return (
                                <Row>
                                    <Col>
                                        <Label style={{ color: colors.red, fontSize: 20 }} >
                                            {index + 1}.- {error}
                                        </Label>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => setIsOpenModalErrores(false)}
                        style={{ ...Styles.button, width: "auto" }}
                    >
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal >
        </>
    );
}


export default ListaCliente;