import TipoVivienda from "../interfaces/TipoVivienda.interface";

const getEstructura = (): TipoVivienda => {
    const tipoVivienda: TipoVivienda = {
        IdTipoVivienda: 0,
        tipoVivienda: "",
    };
    return tipoVivienda;
}

const TipoViviendaModel = {
    getEstructura
}

export default TipoViviendaModel