const convertToNumber = (value: string): string => {
    const arrayNumberASCII = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 46];
    if (value.length === 0) value = "0";
    const letterASCII = value.charAt(value.length - 1).charCodeAt(0)
    const arrayLength = value.split(".").length;
    const array = value.split(".");
    if (arrayLength === 2) {
        if (arrayNumberASCII.includes(letterASCII)) {
            if (array[1].length < 3) {
                value = value.replace(/^0(?=[1-9])/, '');
                return value;
            }
        }
    }
    if (arrayLength === 1) {
        if (arrayNumberASCII.includes(letterASCII)) {
            value = value.replace(/^0(?=[1-9])/, '');
            return value;
        }

    }
    return value.slice(0, -1);
}

const convertToInt = (value: string): string => {
    if (value.length === 0 || value === "00" || value === ".") value = "0";
    if (/^\d+$/.test(value)) {
        if (value !== "0") value = value.replace(/^0+/, '');
        return value;
    }
    return value.slice(0, -1);
}

const singleNumbers = (text: string): string => {
    const ascii: number[] = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 46];
    let value: string = "";

    if (text.split(".").length === 3) return text.slice(0, -1);
    if (
        text.split(".").length === 2 &&
        text.split(".")[1].length > 2 &&
        ascii.includes(text.split(".")[1][0].charCodeAt(0)) &&
        ascii.includes(text.split(".")[1][1].charCodeAt(0))
    ) return text.slice(0, -1);

    value = text.split('').filter(letter => ascii.includes(letter.charCodeAt(0))).join('');

    value = value.length === 0 ? "0" : value;

    value = value.replace(/^0+(?=\d)/, '');

    return formatWithCommas(value);
}

const formatWithCommas = (value: string): string => {
    const parts = value.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join('.');
};

const formatPhoneNumber = (phoneNumber: string): string => {    
    //     // Eliminar cualquier carácter que no sea un dígito
    //     const cleaned = phoneNumber.replace(/\D/g, '');

    //     // Verificar si el número tiene exactamente 10 dígitos
    //     if (cleaned.length !== 10) {
    //         return phoneNumber
    //     }

    //     // Formatear el número en el formato 000-000-00-00
    //     const formatted = `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6, 8)}-${cleaned.slice(8, 10)}`;

    //     return formatted;
    // Eliminar cualquier carácter que no sea un dígito
    const cleaned = phoneNumber.replace(/\D/g, '');

    // Definir la estructura de formato deseada
    const parts = [
        cleaned.slice(0, 3),
        cleaned.slice(3, 6),
        cleaned.slice(6, 8),
        cleaned.slice(8, 10),
    ];

    // Unir las partes con un guion, ignorando las partes vacías
    const formatted = parts.filter(Boolean).join('-');

    return formatted;
};

const Number = {
    convertToNumber,
    convertToInt,
    singleNumbers,
    formatPhoneNumber,
}

export default Number