import { ChangeEvent, FC, useEffect, useState, FocusEvent, KeyboardEvent } from "react";
import Block from "../components/Block.component";
import { Col, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Button, Progress, Input } from "reactstrap";
import imgTzedakaLogo from "../assets/img/logoTzedaka.png";
import Device from "../interfaces/Device.interface";
import DeviceConfig from "../config/Device.config";
import colors from "../config/Colors.config";
import Response from "../interfaces/Response.interface";
import WhatsAppService from "../services/WhatsApp.service";
import { ToastContainer, toast } from "react-toastify";
import { FormGroup, IconButton } from "@material-ui/core";
import { NavigateBefore, NavigateNext, Close, Search } from "@material-ui/icons";
import QrWhatsApp from "../interfaces/QrWhatsApp.iterface";
import { QRCodeSVG } from 'qrcode.react';
import { TapAndPlay } from '@mui/icons-material';
import Styles from "../assets/css/Styles.css";
import Tiempo from "../tools/Tiempo.tool";
import Components from "../tools/Components.tool";
import CorreosService from "../services/Correos.service";
import Table, { TableColumn } from 'react-data-table-component';
import iconRecordatorio from "../assets/icon/recordatorio.png";
import iconNoWifi from "../assets/icon/no-wifi.png";
import Swal from "sweetalert2";
import Number from "../tools/Number.tool";
import ClienteService from "../services/Cliente.service";
import ContactoWhatsApp from "../interfaces/ContactoWhatsApp.interface";
import WhatsAppMensaje from "../interfaces/WhatsAppMensaje.interface";
import WhatsAppModel from "../models/WhatsApp.model";
import WhatsAppMessage from "../components/WhatsAppMessage.componet";
import iconNoSelect from "../assets/icon/casillas-de-verificacion.png";
// import styles from './whatsApp.css';

// interface MensajeWhatsApp {
//     Id: number,
//     titulo: string,
//     texto: string,
// }

const WhatsAppPage: FC = () => {

    const [data] = useState<Device>(DeviceConfig.data());

    const [isOpenModalWhatsApp, setIsOpenModalWhatsApp] = useState<boolean>(false);
    const [qrWhatsApp, setQrWhatsApp] = useState<QrWhatsApp>({ Id: 0, fecha: "", hora: "", qr: "" });
    const [hora, setHora] = useState<string>(Tiempo.getCurrentTime());
    const [texto, setTexto] = useState<string>("");
    const [cargaContactosLista, setCargaContactosLista] = useState<ContactoWhatsApp[]>([]);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [valueProgress, setValueProgress] = useState<number>(0);
    const [isOverMarcarTodos, setIsOverMarcarTodos] = useState<boolean>(false);
    const [isOverDesmarcarTodos, setIsOverDesmarcarTodos] = useState<boolean>(false);
    const [contador, setContador] = useState<number>(0);
    const [labelInfoCargaMasiva, setLabelInfoCargaMasiva] = useState<string>("");
    const [mandar, setMandar] = useState<number>(0);
    const [noMandar, setNoMandar] = useState<number>(0);
    const [mandarFijo, setMandarFijo] = useState<number>(0);
    const [noMandarFijo, setNoMandarFijo] = useState<number>(0);
    const [arrayButton, setArrayButton] = useState<number>(0);
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const [mensajesWhatsAppLista, setMensajesWhatsAppLista] = useState<WhatsAppMensaje[]>([]);
    const [mensajesWhatsApp, setMensajesWhatsApp] = useState<WhatsAppMensaje>(WhatsAppModel.getEstructura());

    let estaVinculadoWhatsApp: boolean = false

    useEffect(() => {
        listarMensajesWhatsApp(0);
    }, []);

    const listarMensajesWhatsApp = async (IdWhatsAppMensaje: number): Promise<void> => {
        const response: Response = await WhatsAppService.listarMensajes(IdWhatsAppMensaje);
        if (response.error === false) {
            if (IdWhatsAppMensaje === 0) {
                const mensajesWhatsAppLista: WhatsAppMensaje[] = response.data.value;
                setMensajesWhatsAppLista(mensajesWhatsAppLista);
            }
            if (IdWhatsAppMensaje > 0) {
                const mensajesWhatsApp: WhatsAppMensaje = response.data.value;
                setMensajesWhatsApp(mensajesWhatsApp);
            }

        }
    }

    useEffect(() => {
        // Llama a getStatusWhatsApp cada 3 segundos
        const interval = setInterval(() => {
            if (estaVinculadoWhatsApp === false) getStatusWhatsApp();
        }, 3000);

        // Limpia el intervalo cuando el componente se desmonte
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getStatusWhatsApp = async (): Promise<void> => {
        if (estaVinculadoWhatsApp === true) return
        const response: Response = await WhatsAppService.getStatusWhatsApp();
        if (response.data.value === null) {
            setIsOpenModalWhatsApp(true);
            setQrWhatsApp(response.data.other);
        } else {
            if (response.data.value === "CONNECTED") {
                toast.success("Cuanta de WhatsApp vinculada.");
                setIsOpenModalWhatsApp(false);
                estaVinculadoWhatsApp = true;
            }
        }
    }

    const listarContactosWhatsApp = async (texto: string): Promise<void> => {
        setHora("Cargando contactos...");
        const response: Response = await ClienteService.obtenerContactos(texto);
        const cargaCorreosLista: ContactoWhatsApp[] = response.data.value;
        setMandar(+response.data.other.mandar)
        setNoMandar(+response.data.other.noMandar)
        setMandarFijo(+response.data.other.mandar)
        setNoMandarFijo(+response.data.other.noMandar)
        setCargaContactosLista(cargaCorreosLista);
        setHora(Tiempo.getCurrentTimeFormat12Hour());
    }

    const guardarDatosWhatsApp = async (): Promise<void> => {
        setIsLoad(true);
        const array: ContactoWhatsApp[] = [...cargaContactosLista];
        const response: Response = await CorreosService.cambiarDatoWhatsApp(array);
        if (response.error === false) { toast.info("Datos guardados"); listarContactosWhatsApp(texto); };
        if (response.error === true) toast.error("Error");
        setIsLoad(false);
    }

    const capitalizeWords = (text: string): string => {
        return text
            .split(" ")                   // Divide el string en palabras
            .map(word =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )                              // Convierte la primera letra de cada palabra a mayúscula
            .join(" ");                    // Une las palabras de nuevo en un solo string
    }

    // const mandarMensajesWhatsAppMasivo = async (): Promise<void> => {
    //     let array: ContactoWhatsApp[] = [...cargaContactosLista.filter((contacto: ContactoWhatsApp) => contacto.mandarWhatsApp === 1)];
    //     let cont: number = 0;
    //     let valueProgress: number;
    //     let texto: string = "";
    //     for (let i = 0; i < array.length; i++) {
    //         const correo: ContactoWhatsApp = array[i];
    //         if (correo.telefono.length > 0) {
    //             cont = cont + 1;
    //             valueProgress = (cont / array.length) * 100
    //             setValueProgress(valueProgress);


    //             if (arrayButton === 1) {
    //                 texto = arrayTextMensajesWhatsApp.find((item: MensajeWhatsApp) =>  item.Id === 1 )?.texto ?? "";
    //             }
    //             if (arrayButton === 2) {
    //                 texto = arrayTextMensajesWhatsApp.find((item: MensajeWhatsApp) =>  item.Id === 2 )?.texto ?? "";
    //             }

    //             if (arrayButton === 3) {
    //                 texto = arrayTextMensajesWhatsApp.find((item: MensajeWhatsApp) =>  item.Id === 3 )?.texto ?? "";
    //             }

    //             await WhatsAppService.sendMessage(correo.telefono, texto)
    //             setContador(cont);
    //         }
    //     }

    // }

    const mandarMensajesWhatsAppMasivo = async (): Promise<void> => {
        let array: ContactoWhatsApp[] = [...cargaContactosLista.filter((contacto: ContactoWhatsApp) => contacto.mandarWhatsApp === 1)];
        let cont: number = 0;
        let valueProgress: number;
        let texto: string = mensajesWhatsApp.texto;

        for (let i = 0; i < array.length; i++) {
            const contacto: ContactoWhatsApp = array[i];

            if (contacto.telefono.length > 0) {
                cont = cont + 1;
                valueProgress = (cont / array.length) * 100;
                setValueProgress(valueProgress);
                texto = texto.replace("$nombreCliente", capitalizeWords(contacto.nombres))
                await WhatsAppService.sendMessage(contacto.telefono, texto);
                setContador(cont);
            }
        }
    };


    const marcarDesmarcar = (newValue: number): void => {
        if (cargaContactosLista.length > 0) {
            let updatedCorreos: ContactoWhatsApp[] = [];
            updatedCorreos = cargaContactosLista.map((correo: ContactoWhatsApp) => { return { ...correo, mandarWhatsApp: newValue } });
            setCargaContactosLista(updatedCorreos);
            setMandar([...updatedCorreos.filter(correo => correo.mandarWhatsApp === 1)].length);
            setNoMandar([...updatedCorreos.filter(correo => correo.mandarWhatsApp === 0)].length);
        } else {
            toast.info("Filtra primero a los clientes");
        }

    }

    const onChangeMandarWhatsApp = (IdCliente: string) => {
        let updatedCorreos: ContactoWhatsApp[] = [];
        updatedCorreos = cargaContactosLista.map((correo: ContactoWhatsApp) => correo.IdCliente === IdCliente ? { ...correo, mandarWhatsApp: correo.mandarWhatsApp === 1 ? 0 : 1 } : correo);
        setCargaContactosLista(updatedCorreos);
        setMandar([...updatedCorreos.filter(correo => correo.mandarWhatsApp === 1)].length);
        setNoMandar([...updatedCorreos.filter(correo => correo.mandarWhatsApp === 0)].length);
    }

    const columnsClientes: TableColumn<ContactoWhatsApp>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > Num. </Label>,
            cell: (contacto: ContactoWhatsApp, index: number) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{index + 1}</Label>,
            width: "100px"
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > CLIENTE</Label>,
            cell: (contacto: ContactoWhatsApp) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{contacto.nombres}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > TELÉFONO </Label>,
            cell: (contacto: ContactoWhatsApp) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{Number.formatPhoneNumber(contacto.telefono)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} >  </Label>,
            cell: (contacto: ContactoWhatsApp) =>
                <>
                    <IconButton
                        style={{ color: colors.blue }}
                        onClick={() => {
                            onChangeMandarWhatsApp(contacto.IdCliente);
                        }}
                    >
                        {contacto.mandarWhatsApp === 1 ? <NavigateBefore /> : <NavigateNext />}
                    </IconButton>
                </>
            ,
        },
    ]

    return (
        <>
            <main style={{ width: "100%", minHeight: "100vh", height: "100vh", maxHeight: "auto", backgroundColor: colors.white01 }} >
                <ToastContainer limit={3} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
                <section style={{ marginLeft: data.isDesktop ? 80 : 0 }} >
                    <Block height={"2vh"} />
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col>
                            <Label style={{ fontSize: 30, }} >
                                SERVICIO DE WHATSAPP WEB
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <img src={imgTzedakaLogo} alt="/*-" style={{ height: 50, marginRight: 15 }} />
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 50 }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, fontWeight: "bold" }} >
                                    Buscar Cliente
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="textBuscar"
                                        type="text"
                                        placeholder="Nombres, Apellido Paterno, Apellido Materno"
                                        value={texto}
                                        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                            setTexto(value)
                                        }}
                                        onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                            if (key === "Enter") listarContactosWhatsApp(texto);
                                        }}
                                        onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                            event.target.select();
                                        }}
                                        style={{ ...Styles.input, width: "80%", borderBottomColor: colors.blue, }}
                                    />
                                    <InputGroupText
                                        style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white01, cursor: "pointer" }}
                                        onClick={() => {
                                            setTexto("");
                                            // Tiempo.sleep(1).then(() => setCargaCorreosLista([]));
                                            Components.focus("textBuscar");

                                        }}
                                    >
                                        <Close />
                                    </InputGroupText>
                                    <InputGroupText
                                        style={{ ...Styles.input, color: colors.blue, backgroundColor: colors.white01, cursor: "pointer" }}
                                        onClick={() => {
                                            // listarClientes(textBuscar);
                                            // Components.focus("textBuscar");
                                            if (texto.length > 0) {
                                                listarContactosWhatsApp(texto);
                                            } else {
                                                toast.warning("No has especificado la búsqueda");
                                                Components.focus("textBuscar");
                                            }
                                        }}
                                    >
                                        <Search />
                                    </InputGroupText>
                                </InputGroup>
                                <Label
                                    style={{ marginTop: 5, color: colors.blue, cursor: "pointer", textDecorationLine: "underline" }}
                                    onClick={() => listarContactosWhatsApp("")}
                                >
                                    Listar a todos los contactos
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <IconButton
                                style={{ marginRight: 15, color: colors.blue }}
                                onClick={async () => {
                                    // setEstaVinculadoWhatsApp(false);
                                    const response: Response = await WhatsAppService.getStatusWhatsApp();
                                    if (response.data.status === 200) {
                                        Swal.fire({
                                            icon: "question",
                                            title: "Desvincular cuenta de whatsApp",
                                            text: "¿Quieres desvincular la cuenta actual para vincularte con otra?",
                                            showConfirmButton: true,
                                            confirmButtonColor: colors.blue,
                                            denyButtonColor: colors.blue,
                                            cancelButtonText: "No",
                                            confirmButtonText: "Si",
                                            reverseButtons: true,
                                            showCancelButton: true,
                                            timer: 10000,
                                            timerProgressBar: true,
                                            footer: ` <label> <i> No podrás recuperar la cuenta actualmente vinculada </i> </label>`
                                        }).then(async (response) => {
                                            if (response.isConfirmed) {
                                                WhatsAppService.logoutWhatsApp();
                                                setIsOpenModalWhatsApp(true);
                                                estaVinculadoWhatsApp = false;
                                            }
                                        })
                                    } else {
                                        setIsOpenModalWhatsApp(true);
                                        estaVinculadoWhatsApp = false;
                                    }

                                }}
                            >
                                <TapAndPlay />
                            </IconButton>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 5 }} >
                        <Col>
                            <Label style={{ fontWeight: "bold" }} >
                                Ultima Actualización: {hora}
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <Button
                                style={{ ...Styles.button, width: 120 }}
                                onClick={() => setIsOpenModal(true)}
                            >
                                Mandar
                            </Button>
                            <Button
                                style={{ ...Styles.button, width: isLoad ? 130 : 120, marginLeft: 15, backgroundColor: isLoad ? colors.blueSlate : colors.blue, borderColor: isLoad ? colors.blueSlate : colors.blue }}
                                onClick={() => guardarDatosWhatsApp()}
                            >
                                {isLoad ? "Guardando..." : "Guardar"}
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                        <Col>
                            <Label>
                                Hay {mandarFijo} clientes seleccionados para mandar y {noMandarFijo} para no mandar
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "right" }} >
                            <Label
                                style={{ marginRight: 15, color: colors.blue, textDecorationLine: isOverDesmarcarTodos ? "underline" : "", cursor: "pointer" }}
                                onClick={() => marcarDesmarcar(0)}
                                onMouseOver={() => setIsOverDesmarcarTodos(true)}
                                onMouseLeave={() => setIsOverDesmarcarTodos(false)}
                            >
                                Desmarcar Todos
                            </Label>
                            <Label
                                style={{ marginLeft: 15, color: colors.blue, textDecorationLine: isOverMarcarTodos ? "underline" : "", cursor: "pointer" }}
                                onClick={() => marcarDesmarcar(1)}
                                onMouseOver={() => setIsOverMarcarTodos(true)}
                                onMouseLeave={() => setIsOverMarcarTodos(false)}
                            >
                                Marcar Todos
                            </Label>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 0 }} >
                        {
                            cargaContactosLista.length === 0 ?
                                <center>
                                    <Block height={data.isDesktop ? 10 : 0} />
                                    <img src={iconRecordatorio} alt="/*-" style={{ height: "30vh" }} />
                                </center>
                                :
                                <Row>
                                    <Col>
                                        <Row>
                                            <Label style={{ fontSize: 18, color: colors.blue }} >
                                                Hay {noMandar} clientes a los que no se les mandara mensaje
                                            </Label>
                                        </Row>
                                        <Row>
                                            <div style={{ height: "50vh", overflowY: "auto" }} >
                                                <Table
                                                    // title={<Label>{cantidadClientes}</Label>}
                                                    columns={columnsClientes}
                                                    data={[...cargaContactosLista.filter(correo => correo.mandarWhatsApp === 0)]}
                                                    noDataComponent="Tabla vacía"
                                                //fixedHeader
                                                />
                                            </div>
                                        </Row>

                                    </Col>
                                    <Col>
                                        <Row>
                                            <Label style={{ fontSize: 18, color: colors.blue }} >
                                                Hay {mandar} clientes a los que se les mandara mensaje
                                            </Label>
                                        </Row>
                                        <Row>
                                            <div style={{ height: "50vh", overflowY: "auto" }} >
                                                <Table
                                                    // title={<Label>{cantidadClientes}</Label>}
                                                    columns={columnsClientes}
                                                    data={[...cargaContactosLista.filter(correo => correo.mandarWhatsApp === 1)]}
                                                    noDataComponent="Tabla vacía"
                                                />
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                        }
                    </Row>
                </section>
            </main>
            <Modal
                isOpen={isOpenModalWhatsApp}
                style={{ fontFamily: "Montserrat", maxWidth: "50%" }}
            >
                <ModalHeader
                    style={{ backgroundColor: colors.blue }}
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.white01, color: colors.blue }}
                            onClick={() => {
                                setIsOpenModalWhatsApp(false);
                            }}
                        >
                            <Close />
                        </IconButton>
                    }
                >
                    <Label style={{ color: colors.white01 }} >Vinculación de cuenta de WhatsApp</Label>
                </ModalHeader>
                <ModalBody>
                    <center>
                        {
                            qrWhatsApp.qr.length === 0 ?
                                <>
                                    <img src={iconNoWifi} alt="no data" style={{ height: "30vh" }} />
                                    <br />
                                    <Label style={{ fontSize: 20, marginTop: 20 }} >
                                        No se pudo obtener el código Qr <br /> verifique el servicio de whatsApp-api
                                    </Label>
                                </>
                                :
                                <>
                                    <QRCodeSVG value={qrWhatsApp.qr} size={300} />
                                    <br />
                                    <Label style={{ marginTop: 10, fontSize: 18 }} >
                                        Escanee el QR desde desde su app de WhatsApp <br /> para poder vincular su cuenta con el sistema.
                                    </Label>
                                    <br />
                                    <i style={{ fontSize: 16 }} ><strong>Si no se vincula al escanear el QR vuélvalo a intentar</strong></i>
                                </>
                        }
                    </center>
                </ModalBody>
                {/* <ModalFooter>

                </ModalFooter> */}
            </Modal>


            <Modal
                isOpen={isOpenModal}
                style={{ fontFamily: "Montserrat", maxWidth: "50%", marginTop: 10 }}
                onOpened={() => {
                    setContador(0);
                    setValueProgress(0);
                    let array: ContactoWhatsApp[] = [...cargaContactosLista.filter(correo => correo.mandarWhatsApp === 1)];
                    setLabelInfoCargaMasiva(`Preparando el envió masivo para ${array.length} contacto(s)`);
                    setMensajesWhatsApp(WhatsAppModel.getEstructura());
                }}
                onClosed={() => { setContador(0); setValueProgress(0); setArrayButton(0); }}
            >
                <ModalHeader
                    style={{ backgroundColor: colors.blue }}
                    close={
                        <Col style={{ textAlign: "right" }} >
                            <IconButton
                                style={{ backgroundColor: colors.white01, color: colors.blue }}
                                onClick={() => setIsOpenModal(false)}
                            >
                                <Close />
                            </IconButton>
                        </Col>
                    }
                >
                    <Label style={{ color: colors.white01, fontSize: 24 }} >
                        Configuración de envió masivo de whatsApp
                    </Label>
                </ModalHeader>

                <ModalBody style={{ minHeight: "65vh", height: "65vh", maxHeight: "65vh", overflowY: "scroll" }} >
                    <Row>
                        <Col>
                            <Label style={{ fontSize: 20 }} >
                                {labelInfoCargaMasiva}
                            </Label>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.black, fontSize: 18, }} >
                                    Seleccionar plantilla de mensaje de WhatsApp
                                </Label>
                                <Input
                                    type="select"
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        const mensajeWhatsApp: WhatsAppMensaje | undefined = mensajesWhatsAppLista.find((item: WhatsAppMensaje) => item.IdWhatsAppMensaje === +value);
                                        if (mensajeWhatsApp) setMensajesWhatsApp(mensajeWhatsApp); else setMensajesWhatsApp(WhatsAppModel.getEstructura());
                                    }}
                                    // onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                    //     event.target.select();
                                    // }}
                                    style={{ ...Styles.input }}
                                >
                                    <option value={0}>Seleccione una plantilla</option>
                                    {
                                        mensajesWhatsAppLista.map((item: WhatsAppMensaje) => {
                                            return (
                                                <option value={item.IdWhatsAppMensaje}>{item.titulo}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: 20 }} >
                        <Col style={{ padding: 20, textAlign: "center" }} >
                            {
                                mensajesWhatsApp.IdWhatsAppMensaje === 0 ?
                                    <>
                                        <img src={iconNoSelect} alt="no data" style={{ height: 180 }} />
                                        <br /><br />
                                        <Label style={{ fontSize: 18 }} >No has seleccionado un plantilla</Label>
                                    </>
                                    :
                                    <center>
                                        <WhatsAppMessage mensajesWhatsApp={mensajesWhatsApp} />
                                    </center>
                            }
                        </Col>
                    </Row>


                </ModalBody>

                <ModalFooter>
                    <Row style={{ marginRight: 0, marginLeft: 0, width: "100%" }} >
                        <Col>
                            <Progress
                                className="my-2"
                                value={valueProgress}
                            >
                                {valueProgress === 100 ? valueProgress : valueProgress.toFixed(2)} %
                            </Progress>
                        </Col>
                    </Row>
                    {/* <Row style={{ marginRight: 0, marginLeft: 0, width: "100%" }} >
                        <Col>
                            <Label>
                                Se han mando {contador} de {[...cargaContactosLista.filter((contacto: ContactoWhatsApp) => contacto.mandarWhatsApp === 1)].length}
                            </Label>
                        </Col>
                    </Row> */}
                    <Row style={{ marginRight: 0, marginLeft: 0, width: "100%" }} >
                        <Col style={{ textAlign: "left", maxWidth: "50%", minWidth: "50%", width: "50%" }} >
                            {
                                [...cargaContactosLista.filter(correo => correo.mandarWhatsApp === 1)].length === 0 ?
                                    <Label style={{ color: colors.warning, fontSize: 18 }} >
                                        <strong>No hay clientes asignados para mandar mensajes de WhatsApp</strong>
                                    </Label>
                                    :
                                    <Label>
                                        Se han mando {contador} de {[...cargaContactosLista.filter((contacto: ContactoWhatsApp) => contacto.mandarWhatsApp === 1)].length}
                                    </Label>
                            }
                        </Col>
                        <Col style={{ textAlign: "right", maxWidth: "50%", minWidth: "50%", width: "50%" }} >
                            <Button
                                onClick={() => {
                                    if ([...cargaContactosLista.filter(correo => correo.mandarWhatsApp === 1)].length > 0) {

                                        if (mensajesWhatsApp.IdWhatsAppMensaje !== 0) {
                                            mandarMensajesWhatsAppMasivo();
                                        } else {
                                            Swal.fire({
                                                icon: "warning",
                                                title: "¡Atención!",
                                                text: "No has especificado la plantilla de mensaje",
                                                showConfirmButton: true,
                                                confirmButtonColor: colors.blue,
                                                confirmButtonText: "Ok",
                                                reverseButtons: true,
                                                timer: 10000,
                                                timerProgressBar: true,
                                            })
                                        }
                                    }
                                }}
                                style={{ ...Styles.button, width: "auto" }}
                            >
                                Mandar
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal >
        </>
    )
}


export default WhatsAppPage