import { SolicitudCredito } from "../interfaces/SolicitudCredito.interface";

const getEstructura = (): SolicitudCredito => {
    const data: SolicitudCredito = {
        IdSolicitudCredito: "",
        IdCliente: "",
        tipoCredito: {
            IdTipoCredito: 0,
            tipoCredito: ""
        },
        montoSolicitado: {
            IdMontoSolicitado: 0,
            montoSolicitado: "0.00"
        },
        montoAprobado: "0.00",
        plazoCredito: {
            IdPlazoCredito: 0,
            plazoCredito: "",
            descripcion: "",
        },
        formaPago: {
            IdFormaPago: 0,
            formaPago: ""
        },
        status: {
            IdStatus: 1,
            status: ""
        },
        fechaCreacion: "",
        horaCreacion: "",
        fechaModificacion: "",
        horaModificacion: ""
    }
    return data;
}

const validarDatos = (solicitudCredito: SolicitudCredito): { esValido: boolean, erroresLabel: string[] } => {
    let esValido: boolean = true;
    let erroresLabel: string[] = [];

    if (solicitudCredito.tipoCredito.IdTipoCredito === 0) {
        esValido = false;
        erroresLabel.push("Falta especificar el tipo de crédito");
    }

    if (solicitudCredito.montoSolicitado.IdMontoSolicitado === 0) {
        esValido = false;
        erroresLabel.push("Falta especificar el monto del crédito");
    }

    if (solicitudCredito.plazoCredito.IdPlazoCredito === 0) {
        esValido = false;
        erroresLabel.push("Falta especificar el plazo de pago de la de crédito");
    }

    if (solicitudCredito.formaPago.IdFormaPago === 0) {
        esValido = false;
        erroresLabel.push("Falta especificar el tipo de pago de la de crédito");
    }

    return { esValido, erroresLabel };
}

const SolicitudCreditoModel = {
    getEstructura,
    validarDatos,
}

export default SolicitudCreditoModel;