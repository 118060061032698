import Religion from "../interfaces/Religion.interface"

const getEstructura = (): Religion => {
    const religion: Religion = {
        IdReligion: 0,
        religion: ""
    };
    return religion;
}

const ReligionModel = {
    getEstructura
}

export default ReligionModel